'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch');
var debounce = require('lodash/function/debounce');
var defer = require('lodash/function/defer');

var domCache = require('dom-cache');
var lazyImage = require('lazy-image');
var focalPoint = require('focal-point');
// Note: if you want this to work, you must provide your own, app specific css
// based on the app breakpoints.
var bodyStateDetection = require('body-state-detection');

var masonry = require('./masonry');
var gridCellsJst = require('./grid-cells-jst');
var gridCellJst = require('./grid-cell-jst');

// var ua = window.navigator.userAgent.toLowerCase();
var isFirefox = true; //ua.indexOf('gecko') !== -1 && ua.indexOf('firefox') !== -1;
var dom;
var state;
var options;
var swInstance;

// Give each image size a weighted value for comparison purposes.
var imageSizeMap = {
	'small': 0,
	'medium': 1,
	'large': 2,
	'xlarge': 3
};

var viewportMap = {
	small: {
		batchSize: 10,
		gridImageSize: 'medium'
	},
	medium: {
		batchSize: 10,
		gridImageSize: 'medium'
	},
	large: {
		batchSize: 15,
		gridImageSize: 'large'
	},
	xlarge: {
		batchSize: 20,
		gridImageSize: 'large'
	},
	xxlarge: {
		batchSize: 25,
		gridImageSize: 'large'
	}
};

var setupOptions = function(opts) {

	var defaultDataOptions = {
		isLink: true,
		sizes: {},
		width: 0,
		height: 0,
		hasFocal: false,
		x: 0,
		y: 0
	};

	// Merge data options.
	opts.data.forEach(function(obj, i) {

		opts.data[i] = $.extend({curState: bodyStateDetection.getState()}, defaultDataOptions, obj);

	});

	options = {
		data: [],
		gutterSize: 0,
		infiniteOffset: 500,
		watchOffset: 0,
		watchThrottle: 250,
		lazyDebounceWait: 500,
		lazyOffset: 100,
		// Proxy lazy-image options.
		customScroller: '',
		customScrollerEvent: ''
	};

	$.extend(options, dom.grid.data('image-grid-options'), opts);

};

var getItemIndex = function($item) {

	$item = $item.is('.image-grid__item') ? $item : $item.closest('.image-grid__item');

	return parseInt($item.attr('data-image-grid-index'), 10);

};

var getItemByIndex = function(index) {

	return dom.grid.find('[data-image-grid-index="' + index + '"]');

};

var getItems = function() {

	return dom.grid.find('.image-grid__item');

};

var isMasonry = function() {

	return options.isMasonry;

};

var isCustomScroller = function() {

	return !!options.customScroller;

};

var setupInitialState = function() {

	state = {};

	state.lastItemIndex = null;

};

var saveLastItemIndex = function() {

	if (isMasonry()) {

		state.lastItemIndex = masonry.getLastItemIndex();

	} else {

		state.lastItemIndex = getItemIndex(getItems().last());

	}

};

var setupDom = function() {

	dom = {};

	dom.grid = $('.image-grid');

};

var addEventHandlers = function() {

	domCache.document.on('image_grid_state_change.image-grid', function() {

		// The number of grid columns has changed and the grid has been resorted.

		lazyImage.refresh();
		swInstance.refresh();

	});

	domCache.document.on('device_state_changed.system-image-grid', function(e) {

		// console.log('device_state_changed');
		// console.log(bodyStateDetection.wasUpsized(e.oldState, e.newState));
		// console.log(bodyStateDetection.wasDownsized(e.oldState, e.newState));

		if (bodyStateDetection.wasUpsized(e.oldState, e.newState)) {

			// We just hit a larger breakpoint, upgrade images if necessary.

			options.data.forEach(function(obj) {

				var oldSize = obj.sizes[viewportMap[e.oldState].gridImageSize];
				var newSize = obj.sizes[viewportMap[e.newState].gridImageSize];

				if (oldSize !== newSize) {

					// This image supports a larger version at this breakpoint.

					// Make sure the new state isn't smaller than a previously
					// visited state. For example, if this image used sizes of
					// small, medium and large at each of those respective states,
					// we wouldn't want to load the medium size when going from
					// the small to medium state, if we have already visited the large
					// state, and thus loaded the large size, which is better than
					// medium.
					if (imageSizeMap[viewportMap[e.newState].gridImageSize] > imageSizeMap[viewportMap[obj.curState].gridImageSize]) {

						// Track the current viewport state used for each image.
						obj.curState = e.newState;

						getItemByIndex(obj.index)
							.find('.image-grid__image')
								.removeClass('scroll-watch-in-view scroll-watch-ignore lazy-image--loaded')
								.attr('data-lazy-image', gridCellJst.getUrl(e.newState, obj));

					}


				}

			});

		} else {

			// We just hit a smaller breakpoint, downgrade images we haven't
			// downloaded yet.

			dom.grid.find('[data-lazy-image]').each(function() {

				var $this = $(this);
				var $item = $this.closest('.image-grid__item');
				var itemIndex = getItemIndex($item);
				var imgObj = options.data[itemIndex];
				var oldSize = imgObj.sizes[viewportMap[e.oldState].gridImageSize];
				var newSize = imgObj.sizes[viewportMap[e.newState].gridImageSize];

				if (oldSize !== newSize) {

					imgObj.curState = e.newState;

					$this.attr('data-lazy-image', gridCellJst.getUrl(e.newState, imgObj));

				}

			});

		}

		lazyImage.refresh();
		swInstance.refresh();

	});

};

// Wait 1 second after infinite content is injected before trying to lazy load
// the images.
var refreshLazyImage = debounce(lazyImage.refresh, 1000);
// var refreshLazyImage = lazyImage.refresh;

var generateCells = function() {

	var deviceSize = bodyStateDetection.getState();
	var start;
	var end;

	if (isCustomScroller()) {

		// Infinite scrolling on JS scrollers is not supported, so generate all
		// cells at once.
		start = 0;
		end = options.data.length;

	} else {

		// Check if this is the initial batch of cells.
		start = !state ? 0 : state.lastItemIndex + 1;
		end = start + viewportMap[deviceSize].batchSize;

	}

	return gridCellsJst.template(
		viewportMap[deviceSize].gridImageSize,
		options.isTextHidden,
		start,
		end,
		options.data
	);

};

var init = function(opts) {

	setupDom();
	setupOptions(opts);

	console.log('system image-grid options: ', options);

	// Generate and inject initial batch of cells.
	dom.grid.html(generateCells());

	if (isMasonry()) {

		masonry.init(dom.grid, options.isTextHidden, options.data);

	} else {

		// We only need to apply focal points to non-masonry layouts b/c
		// masonry layouts show the full image.
		focalPoint.init({
			target: '.image-grid .image-grid__image',
			data: options.data
		});

	}

	lazyImage.init({
		target: '.image-grid__image',
		offset: options.lazyOffset,
		debounce: true,
		debounceWait: options.lazyDebounceWait,
		customScroller: options.customScroller,
		customScrollerEvent: options.customScrollerEvent
	});

	// If we have native scrolling, use ScrollWatch to implement infinite
	// scrolling and watching so we can hide images when they go offscreen
	// to increase performance and fix FF memory leaks.
	if (!isCustomScroller()) {

		setupInitialState();
		saveLastItemIndex();
		addEventHandlers();

		if (isFirefox) {

			dom.grid.addClass('image-grid--firefox');

		}

		swInstance = new ScrollWatch({
			watch: '.image-grid__image-wrapper',
			watchOnce: false,
			watchOffset: options.watchOffset,
			scrollThrottle: options.watchThrottle,
			resizeThrottle: options.watchThrottle,
			// Only apply this class if we think this is Firefox. This class
			// is used in the css to reset the background image for memory
			// issues.
			inViewClass: 'image-grid__image-wrapper--is-viewable',
			infiniteScroll: true,
			infiniteOffset: options.infiniteOffset,
			onInfiniteYInView: function(/*data*/) {

				console.log('infinite y in view...');

				defer(function() {

					var html = generateCells();

					if (html.length) {

						dom.grid.append(html);

						if (isMasonry()) {

							// Sort the newly injected cells.
							masonry.addItems();

						}

						saveLastItemIndex();

						refreshLazyImage();

						swInstance.refresh();

						domCache.triggerCustomEvent({
							type: 'image_grid_cells_added'
						});

					}

				});

			}

		});

	}

};

var destroy = function() {

	domCache.document.off('.system-image-grid');
	dom = null;
	options = null;
	state = null;
	refreshLazyImage.cancel();
	lazyImage.destroy();
	focalPoint.destroy();
	masonry.destroy();

	if (swInstance) {

		swInstance.destroy();
		swInstance = null;

	}

};

module.exports = {

	init: init,
	destroy: destroy,
	getItemIndex: getItemIndex,
	getItemByIndex: getItemByIndex,
	getItems: getItems,
	isMasonry: isMasonry

};
