'use strict';

var notify = require('notify');

var dom = require('form-widget-dom');

var show = function(msg) {

	dom.disableSubmit();

	notify.show({
		target: dom.inputContainer,
		msg: msg
	});

	dom.document.one('click', function() {

		notify.hide();

	});

};

module.exports = {
	show: show
};
