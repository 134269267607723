'use strict';

var dom = require('form-widget-dom');

var init = function() {

	if (dom.fbForm.find('.fb-signature').length) {

		dom.head.append('<link href="https://fonts.googleapis.com/css?family=Satisfy" rel="stylesheet">');

	}

};

module.exports = {
	init: init
};
