
; require("/srv/www/html/system/apps/portfolio/src/js/slideshow.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */

(function(api, $, undefined){


	var	state = {},

		dom = {},

		settings = {},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupDom = function() {

			dom = {
				document: core.dom.getElement({ key: "document" })
			};

		},

		setupInitialState = function(){

			state = {
				playTimer: null
			};

		},

		addEventHandlers = function(){

			dom.document.one("slideshow_active_image_updated.autoplay", function() {

				if (settings.autoplay && slideshow.getImgCount() > 1) {

					play();

				}

			});

			dom.document.on("slideshow_control_fired.autoplay", function(e) {

				if (e.controlType === "play") {

					play();

				}

				else if (e.controlType === "pause" || e.controlType === "slider_start") {

					pause();

				}

			});

			dom.document.on("slideshow_active_image_updated.autoplay", function(e) {

				if (e.changedBy === "user") {

					pause();

				}

			});

		},

		play = function(){

			state.playTimer = window.setInterval(function() {

				core.triggerCustomEvent({
					type: "slideshow_autoplay_advance"
				});

			}, settings.effectDuration + settings.delay);

		},

		pause = function(){

			window.clearInterval(state.playTimer);
			state.playTimer = null;

		},

		destroy = function() {

			pause();

			dom.document.off(".autoplay");

		};

	api.init = function(){

		saveSettings();

		setupDom();

		setupInitialState();

		addEventHandlers();

		slideshow.addCleanupItem(destroy);

		slideshow.triggerModuleReady("autoplay");

	};

	api.isPaused = function(){
		return state.playTimer === null;
	};

	api.isPlaying = function(){
		return !api.isPaused();
	};

}(window.slideshow.autoplay = window.slideshow.autoplay || {}, jQuery));
}).call(global, module, undefined, undefined);
