'use strict';

var dom = require('form-widget-dom');

module.exports = {

	init: function() {

	},

	isAddressType: function($col) {

		return $col.hasClass('fb-address-type');

	},

	getVal: function($col) {

		var $els = dom.getFieldInputs($col);
		var name = dom.getFieldTitleText($col);
		var vals = [];

		$els.each(function(i) {

			var $el = $els.eq(i);

			vals.push({
				name: dom.getNestedFieldTitleText($el),
				value: $el.val()
			});

		});

		return {
			name: name,
			value: vals
		};

	}

};
