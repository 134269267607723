'use strict';

var dom = require('form-widget-dom');
var settings = require('form-widget-settings');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');

// Set the button text to match the selected option.
var sync = function($target) {

	if (!settings.useCustomSelect) {

		return;

	}

	$target.each(function(i, el) {

		var $this = $(el);
		// Get the text instead of the value b/c codes are used as values for
		// address country and state and we want to display the full names.
		var val = $this.find(':selected').text();

		$this
			.closest('.fb-selectmenu')
				.find('.fb-selectmenu-text')
					.text(val);

	});

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-selectmenu');

	dom.fbForm.on('change.fb-selectmenu', 'select', function() {

		sync($(this));

	});

	emitter.on('fbWidget-address-states-populated', function($target) {

		sync($target);

	});

	emitter.on('fbWidget-familyfriends-field-added', function(data) {

		sync(data.$select);

	});

	emitter.on('fbWidget-rating-radio-clicked', function($target) {

		sync($target);

	});

	emitter.on('fbWidget-dropdown-reset', function($target) {

		sync($target);

	});

	/*dom.fbForm.on('selectmenuchange.fb-selectmenu', 'select', function() {

		$(this).change();

	});

	dom.fbForm.on('focus.fb-selectmenu', '.ui-selectmenu-button', function() {

		$(this).prev().focus();

	});

	dom.fbForm.on('blur.fb-selectmenu', '.ui-selectmenu-button', function() {

		$(this).prev().blur();

	});

	emitter.on('fbWidget-address-states-populated', function($target) {

		refresh($target);

	});

	emitter.on('fbWidget-rating-radio-clicked', function($target) {

		refresh($target);

	});

	emitter.on('fbWidget-dropdown-reset', function($target) {

		refresh($target);

	});

	emitter.on('fbWidget-familyfriends-field-added', function(data) {

		// Remove cloned jQuery UI select box.
		data.$row
			.find('.ui-selectmenu-button, .ui-selectmenu-menu')
				.remove();

		// Setup new jQuery UI select box.
		widgetize(data.$select);

	});*/

};

module.exports = {

	init: function() {

		var $customSelectWrappers;

		if (settings.useCustomSelect) {

			addEventHandlers();

		} else {

			// Remove elements associated with custom selects, leaving only
			// a native select element.

			$customSelectWrappers = dom.fbForm.find('.fb-selectmenu');

			$customSelectWrappers.each(function(i, el) {

				var $wrapper = $(el);
				var $select = $wrapper.find('select').detach();

				$wrapper.replaceWith($select);

			});

		}

	},

	sync: sync

};
