'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');

var init = function() {

	domCache.document.on('focus.placeholder-toggle', '[data-placeholder-toggle]', function() {

		$(this).attr('placeholder', '');

	});

	domCache.document.on('blur.placeholder-toggle', '[data-placeholder-toggle]', function() {

		var $this = $(this);

		$this.attr('placeholder', $this.attr('data-placeholder-toggle'));

	});

};

var destroy = function() {

	domCache.document.off('.placeholder-toggle');

};

module.exports = {

	init: init,
	destroy: destroy

};
