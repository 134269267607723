
; require("/srv/www/html/system/apps/portfolio/src/js/slideshow.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */

(function(api, $, undefined) {

	var data = {},

		state = {},

		settings = {},

		dom = {},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupDom = function() {

			dom = {
				window: core.dom.getElement({ key: "window" }),
				document: core.dom.getElement({ key: "document" }),
				content: core.dom.getElement({ key: "contentWrapper" }),
				viewer: core.dom.getElement({key: "viewer", refresh: true}),
				shield: $("<div id='shield' />")
			};

		},

		saveBorderAndPadding = function() {

			var $wrapper = $(getImgWrapperMarkup()).css({
					position: "absolute",
					opacity: 0,
					display: "block"
				})
				.appendTo(core.dom.getElement({key: "viewer", refresh: true}));

			data.borderSize = {
				top: parseInt($wrapper.css("border-top-width"), 10),
				right: parseInt($wrapper.css("border-right-width"), 10),
				bottom: parseInt($wrapper.css("border-bottom-width"), 10),
				left: parseInt($wrapper.css("border-left-width"), 10)

				// x: getPropertySize($wrapper, "border-left-width", false),
				// y: getPropertySize($wrapper, "border-top-width", false)
			};

			data.padSize = {
				top: parseInt($wrapper.css("padding-top"), 10),
				right: parseInt($wrapper.css("padding-right"), 10),
				bottom: parseInt($wrapper.css("padding-bottom"), 10),
				left: parseInt($wrapper.css("padding-left"), 10)

				// x: getPropertySize($wrapper, "padding-left", false),
				// y: getPropertySize($wrapper, "padding-top", false)
			};

			$wrapper.remove();

		},

		setupInitialState = function(){

			state = {
				winHeight: dom.window.height(),
				winWidth: dom.window.width(),
				prevImg: -1,
				activeImg: 0,
				maxImg: slideshow.getImgCount() - 1
			};

		},

		injectMarkup = function(){

			var images = settings.images,
				wrappers = [];

			dom.shield.appendTo(dom.content);

			$.each(images, function(i, el) {

				// prepare image wrapper markup
				// el.wrapper = $(getImgWrapperMarkup()).appendTo(dom.viewer);
				el.wrapper = $(getImgWrapperMarkup());

				// prepare image markup
				el.mainImgWrapper = $(getImgMarkup()).appendTo(el.wrapper);

				el.largeImage = el.mainImgWrapper.find("img");

				// Add reflection markup.
				el.reflectionWrapper = $(getReflectionMarkup()).appendTo(el.wrapper);

				el.reflectionImage = el.reflectionWrapper.find("img");

				el.reflectionOverlay = el.reflectionWrapper.find(".reflection-overlay");

				// Add loader.
				el.wrapper.append(getLoaderMarkup());

				// prepare before/after css hook markup (useful for styling purposes)
				el.wrapper.prepend(getBeforeAfterHookMarkup());

				scaleImg(i, "init");

				wrappers.push(el.wrapper.get());

			});

			dom.viewer.append.apply(dom.viewer, wrappers);

			// triggerImagesScaled("init");

			core.triggerCustomEvent({
				type: "slideshow_images_markup_injected"
			});

		},

		getBeforeAfterHookMarkup = function(){
			return	"<span class='beforeAfterHook1'></span>" +
					"<span class='beforeAfterHook2'></span>" +
					"<span class='beforeAfterHook3'></span>" +
					"<span class='beforeAfterHook4'></span>";
		},

		getImgWrapperMarkup = function(){

			return "<div class='large-wrapper' />"; // data-index='" + index + "'
		},

		getImgMarkup = function() {

			return "<div class='main-img-wrapper'><img src='/portfolio/images/placeholder_1px.png'></div>";

			//return "<img src='/portfolio/images/placeholder_1px.png'>"; // data-src='" + size + "' class='large' data-raw-height='" + height + "' data-raw-width='" + width + "

		},

		getReflectionMarkup = function() {

			return "<div class='reflection-wrapper'><img class='reflection-image' src='/portfolio/images/placeholder_1px.png'><div class='reflection-overlay'></div></div>";

		},

		getLoaderMarkup = function() {

			return "<div class='loader'><img src='/portfolio/images/loader.gif' width='32' height='32'></div>";

		},

		addEventHandlers = function() {

			dom.document.on("slideshow_start.images", function() {

				updateActiveImg("startup");

			});

			dom.window.on("resize.images", function(){

				var origHeight = state.winHeight,
					origWidth = state.winWidth,
					newHeight = dom.window.height(),
					newWidth = dom.window.width();

				// this condition ensures that scaleImg() is only called in the event that the window is actually resized
				// IE sometimes triggers phantom resize events, which were throwing off later calculations (http://stackoverflow.com/questions/1852751/window-resize-event-firing-in-internet-explorer)
				// This appears to have been fixed in ie9+...commenting for now to fix https://www.photobiz.com/pbadmin/project_task_detail.cfm?taskID=7544
				// if (newHeight !== origHeight || newWidth !== origWidth) {

					state.winHeight = newHeight;
					state.winWidth = newWidth;

					$.each(settings.images, function(i, el) {

						scaleImg(i, "resize");

					});

				// }

			});

			dom.document.on("slideshow_control_fired.images", function(e){

				if (e.controlType === "next") {

					goToImg(state.activeImg + 1, "user");

				} else if (e.controlType === "prev") {

					goToImg(state.activeImg - 1, "user");

				} else if (e.controlType === "thumbs" || e.controlType === "slider") {

					goToImg(e.activeIdx, "user");

				}

			});

			dom.document.on("slideshow_autoplay_advance.images", function(){

				goToImg(state.activeImg + 1, "auto");

			});

		},

		goToImg = function(idx, changedBy){

			state.prevImg = state.activeImg;

			if(idx > state.maxImg){

				state.activeImg = 0; // skip to the beginning

			}

			else if(idx < 0){

				state.activeImg = state.maxImg; // skip to the end

			}

			else {

				state.activeImg = idx;

			}

			updateActiveImg(changedBy);

		},

		isImgCached = function(idx){

			var $img = settings.images[idx].largeImage,
				src = $img.attr("src"),
				position = src.indexOf("placeholder"),
				isCached = position >= 0 ? false : true;

			return isCached;

		},

		updateImgSrc = function(idx){

			settings.images[idx].largeImage
				.add(settings.images[idx].reflectionImage)
				.attr("src", getFullSizeImgUrl(idx));

		},

		triggerImgLoadComplete = function(idx){

			core.triggerCustomEvent({
				type: "slideshow_preload_image_complete",
				prevImgObj: settings.images[state.prevImg],
				activeImgObj: settings.images[state.activeImg],
				imgLoadedObj: settings.images[idx]
			});

		},

		processImgLoadComplete = function(imgIdx){

			// _.log("imgIdx="+imgIdx);

			if (!isImgCached(imgIdx)) {

				api.hideLoader(settings.images[imgIdx]);

				updateImgSrc(imgIdx);

			}

			triggerImgLoadComplete(imgIdx);

		},

		getValidStartIdx = function(targetIdx, buffer) {

			return targetIdx - buffer < 0 ? 0 : targetIdx - buffer;

		},

		getValidEndIdx = function(targetIdx, buffer) {

			return targetIdx + buffer > state.maxImg ? state.maxImg : targetIdx + buffer;

		},

		startLoadingProcess = function(){

			api.preloadImages({

				targetIdx: 0,
				buffer: 2

			}, function(){

				slideshow.triggerModuleReady("images");

			});

		},

		getFullSizeImgKey = function(){

			var winWidth = $(window).width(),
				contentWidth = dom.content.width(),
				defaultLargeWidth = 1500,
				size;

			if(winWidth <= defaultLargeWidth || contentWidth <= defaultLargeWidth ){
				size = "large";
			}

			else {
				size = "xlarge";
			}

			return size;
		},

		getFullSizeImgUrl = function(idx){

			var img = settings.images[idx],
				size = getFullSizeImgKey();

			return img[size];

		},

		updateActiveImg = function(changedBy) {

			var prevImgObj = settings.images[state.prevImg],
				activeImgObj = settings.images[state.activeImg];

			activeImgObj.wrapper.parents("#viewer").children().removeClass("active-image");

			activeImgObj.wrapper.addClass("active-image");

			core.triggerCustomEvent({
				type: "slideshow_active_image_updated",
				"prevImgObj": prevImgObj,
				"activeImgObj": activeImgObj,
				"changedBy": changedBy
			});

		},

		isPortrait = function(height, width){
			return height > width;
		},

		getAspectRatio = function(imgData){
			return imgData.height / imgData.width;
		},

		getReverseAspectRatio = function(imgData){
			return imgData.width / imgData.height;
		},

		getProportionalWidth = function(height, imgData){
			return Math.ceil(height * getReverseAspectRatio(imgData));
		},

		getProportionalHeight = function(width, imgData){
			return Math.ceil(width * getAspectRatio(imgData));
		},

		getPropertySize = function(prop, heightOrWidth, doubled) {

			var propSize = heightOrWidth === "height" ? data[prop].top : data[prop].left;

			if (doubled) {

				propSize += heightOrWidth === "height" ? data[prop].bottom : data[prop].right;

			}

			return propSize;

		},

		getCoverData = function(data) {

			var ret = {};
			var stretchToWidth = {};
			var stretchToHeight = {};

			// Stretch image to container width, maintaining aspect ratio.

			stretchToWidth.width = data.containerWidth;
			stretchToWidth.height = getProportionalHeight(data.containerWidth, {width: data.imgWidth, height: data.imgHeight});

			// Stretch image to container height, maintaining aspect ratio.

			stretchToHeight.width = getProportionalWidth(data.containerHeight, {width: data.imgWidth, height: data.imgHeight});
			stretchToHeight.height = data.containerHeight;

			// Determine which stretch to use.
			// We want the entire container to be filled.

			if (stretchToWidth.height >= data.containerHeight) {

				// Stretch to width filled the container.

				ret = stretchToWidth;

			} else {

				// Stretch to width didn't fill the container, use stretch to height.

				ret = stretchToHeight;

			}

			ret.widthOffset = (ret.width - data.containerWidth) / 2;
			ret.heightOffset = (ret.height - data.containerHeight) / 2;

			return ret;

		},

		getAvailableHeightForImg = function(imgHeight, containerHeight, imgData){

			var imgBorderPadHeight = api.getBorderPadSize(imgData.wrapper, true, "height"),
				imgHeightWithBorderAndPadding = imgHeight + imgBorderPadHeight,
				availableHeight = imgHeight;

			if(imgHeightWithBorderAndPadding > containerHeight){
				availableHeight = containerHeight - imgBorderPadHeight;
			}

			if(availableHeight < 0){
				availableHeight = 0;
			}

			return availableHeight;

		},

		getAvailableWidthForImg = function(imgWidth, containerWidth, imgData){

			var imgBorderPadWidth = api.getBorderPadSize(imgData.wrapper, true, "width"),
				imgWidthWithBorderAndPadding = imgWidth + imgBorderPadWidth,
				availableWidth = imgWidth;

			if(imgWidthWithBorderAndPadding > containerWidth){
				availableWidth = containerWidth - imgBorderPadWidth;
			}

			if(availableWidth < 0){
				availableWidth = 0;
			}

			return availableWidth;

		},

		scaleImg = function(imgIdx, changedBy) {

			var imgIdx = typeof imgIdx !== "undefined" ? imgIdx : state.activeImg,
				imgData = settings.images[imgIdx],
				$wrapper = imgData.wrapper,
				$img = imgData.largeImage,
				$reflectionWrapper = imgData.reflectionWrapper,
				$reflectionImg = imgData.reflectionImage,
				$reflectionOverlay = imgData.reflectionOverlay,
				renderedDimensions = getRenderedImgDimensions(imgIdx),
				imgHeight = renderedDimensions.height,
				imgWidth = renderedDimensions.width;

			$img.width(imgWidth);
			$img.height(imgHeight);

			$reflectionWrapper.height(settings.reflectionHeight);

			$reflectionImg.width(imgWidth);
			$reflectionImg.height(imgHeight);

			$reflectionOverlay.width(imgWidth);

			settings.images[imgIdx].scaledImgDimensions = {
				width: imgWidth,
				height: imgHeight,
				widthOffset: renderedDimensions.widthOffset,
				heightOffset: renderedDimensions.heightOffset
			};

			// Don't trigger images scaled if we're initializing because
			// nothing will be in the DOM and listeners attached to this
			// event need the images to be in the DOM. We'll trigger this
			// event in injectMarkup() after the DOM is updated.
			// if (imgIdx === state.maxImg && changedBy !== "init") {
			if (imgIdx === state.maxImg) {

				triggerImagesScaled(changedBy);

				core.triggerCustomEvent({
					type: "slideshow_images_scaled",
					activeImgObj: settings.images[state.activeImg],
					"changedBy": changedBy
				});

			}

		},

		triggerImagesScaled = function(changedBy) {

			core.triggerCustomEvent({
				type: "slideshow_images_scaled",
				activeImgObj: settings.images[state.activeImg],
				"changedBy": changedBy
			});

		},

		isScaleModeFit = function() {

			/*
				Standard Gallery:
				by default supports stretch and no stretch
				template can turn off stretching

				Reel Gallery:
				by default only supports stretch
				template can turn on no stretch

			*/

			// hasFitSupport: by default Reel galleries should always stretch to fill the height
			// isCPanelSettingFit: checks the actual user selection in the control panel
			// hasFillSupport: templates with borders around images disable fill support b/c the border will get cut off
			return (slideshow.hasFitSupport() && slideshow.isCPanelSettingFit()) || !slideshow.hasFillSupport();

		},

		getAvailableWidthByGalleryType = function(contentWidth, availableHeight, imgData) {

			// For Reel galleries that support fill, the image width should not be constrained
			// by the content area, essentially "filling" by height. This "fill by height" mode
			// can also be enabled by setting a flag on the template level.

			// Changing default scale to fill behavior to fill by height.
			if (settings.galleryType === "reel" || settings.fillByHeight /*|| !isScaleModeFit()*/) {

				return getProportionalWidth(availableHeight, imgData);

			} else {

				return getAvailableWidthForImg(contentWidth, contentWidth, imgData);

			}

		},

		// TODO: use settings.imageTarget instead of assuming core.dom.content
		getRenderedImgDimensions = function(idx){

			var imgData = settings.images[idx],
				contentHeight = dom.content.height() - settings.reflectionHeight,
				contentWidth = dom.content.width(),
				rawHeight = imgData.height,
				rawWidth = imgData.width,
				scaleToFit = isScaleModeFit(),
				restrictedHeight,
				restrictedWidth,
				availableHeight,
				availableWidth,
				dimensions = {};

			if(scaleToFit){

				restrictedHeight = rawHeight > contentHeight ? contentHeight : rawHeight; // rendered height may be restricted by its raw size, or by the content height
				restrictedWidth = rawWidth > contentWidth ? contentWidth : rawWidth; // rendered width may be restricted by its raw size, or by the content width
				availableHeight = getAvailableHeightForImg(restrictedHeight, contentHeight, imgData);
				availableWidth = getAvailableWidthForImg(restrictedWidth, contentWidth, imgData);

			}

			else{ // scaleToFill

				availableHeight = getAvailableHeightForImg(contentHeight, contentHeight, imgData);
				availableWidth = getAvailableWidthByGalleryType(contentWidth, availableHeight, imgData);

			}


			if(isPortrait(rawHeight, rawWidth)){ // height takes priority, set width dynamically based on available height
				dimensions.height = availableHeight;
				dimensions.width = getProportionalWidth(availableHeight, imgData);
			}

			else { // width takes priority, set height dynamically based on available width


				if (scaleToFit) {

					var newHeight = getProportionalHeight(availableWidth, imgData);

					if(newHeight > availableHeight){ // whoa! the new height is taller than the available space
						dimensions.height = availableHeight;
						dimensions.width = getProportionalWidth(availableHeight, imgData);
					}

					else{ // the new height fits
						dimensions.width = availableWidth;
						dimensions.height = newHeight;
					}

				} else { // scaleToFill

					// At this point, we are in fill mode with a landscape image.
					// We want to stretch the image so the entire available
					// space is covered by the image. In other words, css cover.

					dimensions = getCoverData({
						imgWidth: rawWidth,
						imgHeight: rawHeight,
						containerWidth: availableWidth,
						containerHeight: availableHeight
					});

					// console.log(dimensions);

					// Old logic stretched image to fill available width.
					// var newWidth = availableWidth,
					// 	newHeight = getProportionalHeight(newWidth, imgData),
					// 	heightOverflow = newHeight - availableHeight,
					// 	heightOffset = heightOverflow / 2;

					// dimensions.width = newWidth;
					// dimensions.height = newHeight;
					// dimensions.heightOffset = heightOffset;

				}

			}

			return dimensions;

		},

		destroy = function() {

			dom.document.off(".images");

		};

	api.getBorderSize = function($wrapper, doubled, heightOrWidth){

		return getPropertySize("borderSize", heightOrWidth, doubled);

	};

	api.getPadSize = function($wrapper, doubled, heightOrWidth) {

		return getPropertySize("padSize", heightOrWidth, doubled);

	};

	api.getBorderPadSize = function($wrapper, doubled, heightOrWidth) {

		doubled = typeof doubled !== "undefined" ? doubled : true;

		return api.getBorderSize($wrapper, doubled, heightOrWidth) + api.getPadSize($wrapper, doubled, heightOrWidth);

	};

	api.hideLoader = function(imgObj) {

		imgObj.wrapper
			.find(".loader")
			.hide();

	};

	api.showLoader = function(imgObj) {

		imgObj.wrapper
			.find(".loader")
			.show();

	};

	api.preloadImages = function(options, batchCallback) {

		var defaults = {
				targetIdx: 0,
				buffer: 2
			},
			opts = $.extend(true, {}, defaults, options),
			startIdx = getValidStartIdx(opts.targetIdx, opts.buffer),
			endIdx = getValidEndIdx(opts.targetIdx, opts.buffer),
			imgsToLoad,
			promises = [];

		imgsToLoad = _.range(startIdx, endIdx + 1);

		$.each(imgsToLoad, function(index, imgIdx) {

			var src = getFullSizeImgUrl(imgIdx);

			promises.push(core.util.loadImage(src).then(function() {

				processImgLoadComplete(imgIdx);

			}));

		});

		$.when.apply(null, promises).done(function() {

			// _.log("batch load complete");

			if (batchCallback) {

				batchCallback({
					firstImgLoaded: startIdx,
					lastImgLoaded: endIdx
				});

			}

		});

	};

	api.init = function() {

		saveSettings();

		setupDom();

		setupInitialState();

		saveBorderAndPadding();

		injectMarkup();

		addEventHandlers();

		startLoadingProcess();

		slideshow.addCleanupItem(destroy);

	};

}(window.slideshow.images = window.slideshow.images || {}, jQuery));

}).call(global, module, undefined, undefined);
