
; require("/srv/www/html/system/apps/portfolio/src/js/phoenix.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */

(function(api, $, undefined) {

	// Private variables and methods.

	var data = core.data,

		// Cache factory pattern from MSDN post: http://msdn.microsoft.com/en-us/magazine/gg723713.aspx
		createCache = function(requestFunction) {

			var cache = {};

			return function(key, callback) {

				if (!cache[key]) {

					cache[key] = $.Deferred(function(defer) {

						requestFunction(defer, key);

					}).promise();

				}

				return cache[key].done(callback);

			};

		};

	// Public properties and methods.

	api.loadImage = createCache(function(defer, url) {

		var image = new Image();

		function cleanUp() {

			image.onload = image.onerror = null;
		}

		defer.then(cleanUp, cleanUp);

		image.onload = function() {

			defer.resolve(url);

		};

		image.onerror = defer.reject;

		image.src = url;

	});

	api.isTouch = function() {

		return core.dom.getElement({key: "html"}).hasClass("touch");

	};

	api.getInlinedStyleValue = function($el, prop) {

		return $el.prop("style")[prop];

	};

	api.getProportionalHeight = function(width) {

		return Math.ceil(width * data.aspect);

	};

	api.getProportionalWidth = function(height) {

		return Math.ceil(height * data.reverseAspect);

	};

	api.isNumber = function(num) {

		return !isNaN(parseFloat(num)) && isFinite(num);

	};

	api.addHttpProtocol = function(uri) {

		return uri.substr(0, 7) !== "http://" && uri.substr(0, 8) !== "https://" ? "http://" + uri : uri;

	};

	api.argumentsObjectToArray = function(argumentsObject) {

		// Use Array's native slice method to "convert" argumentsObject into a true array.
		return Array.prototype.slice.call(argumentsObject, 0);

	};

	api.mergeDefaultProperties = function(mainDefaults, argumentsObject) {

		var argumentsArray = this.argumentsObjectToArray(argumentsObject);

		// Perform a deep copy merge of all passed in objects.
		return $.extend.apply(null, [true, mainDefaults].concat(argumentsArray));

	};

	api.getMax = function(array) {

		var maxNums = array;

		// Filter out any NaN.
		$.each(maxNums, function(i, el) {

			if (window.isNaN(el)) {

				maxNums = maxNums.splice(i, 1);

			}

		});

		return Math.max.apply(Math, maxNums);

	};

	api.getMin = function(array) {

		return Math.min.apply(Math, array);

	};

	api.getSmartCenterPadding = function(props) {

		var padding = {top: 0, bottom: 0},
			extraHeight = 0,
			heightDiff = 0,
			windowHeight = props.windowHeight,
			mainWrapperHeight = props.mainWrapperHeight,
			topHeightAllocation = props.topHeightAllocation,
			bottomHeightAllocation = props.bottomHeightAllocation,
			totalHeightAllocation = topHeightAllocation + bottomHeightAllocation;

		// Determine how much extra space we actually have to deal with.
		extraHeight = windowHeight - totalHeightAllocation - mainWrapperHeight;

		if (extraHeight > 0) {

			// Determine how to allocate the extra space. If the top and bottom
			// heights are not equal, the first thing we need to do is make them
			// equal. This will center the template "content".

			heightDiff = topHeightAllocation - bottomHeightAllocation;

			if (heightDiff < 0) {

				// We need to initially allocate space to the top.

				heightDiff *= -1;

				if (heightDiff > extraHeight) {

					// Trying to allocate more space than we have.

					heightDiff = extraHeight;

				}

				padding.top = heightDiff;

				extraHeight -= heightDiff;

				if (extraHeight > 0) {

					// We still have extra space to allocate, so apply it evenly now to
					// keep the "content" centered.

					extraHeight /= 2;

					padding.top += extraHeight;
					padding.bottom += extraHeight;

				}

			} else if (heightDiff > 0) {

				// We  need to intially allocate space to the bottom.

				if (heightDiff > extraHeight) {

					// Trying to allocate more space than we have.

					heightDiff = extraHeight;

				}

				padding.bottom = heightDiff;

				extraHeight -= heightDiff;

				if (extraHeight > 0) {

					// We still have extra space to allocate, so apply it evenly now to
					// keep the "content" centered.

					extraHeight /= 2;

					padding.top += extraHeight;
					padding.bottom += extraHeight;

				}

			} else {

				// The top and bottom are equal. Allocate the extra space
				// equally between the two.

				extraHeight /= 2;

				padding.top += extraHeight;
				padding.bottom += extraHeight;

			}

		}

		return padding;

	};

})(window.core.util = window.core.util || {}, jQuery); // Execute the anonymous function.

}).call(global, module, undefined, undefined);
