
; require("/srv/www/html/system/apps/portfolio/src/js/vendor/jquery-ui.js");
require("/srv/www/html/system/modules/vendor/underscore/underscore.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
function toArray(strg) {

	strg = strg.replace(/left|top/g,'0px');
	strg = strg.replace(/right|bottom/g,'100%');
	strg = strg.replace(/([0-9\.]+)(\s|\)|$)/g,"$1px$2");

	var res = strg.match(/(-?[0-9\.]+)(px|\%|em|pt)\s(-?[0-9\.]+)(px|\%|em|pt)/);

	return [parseFloat(res[1],10),res[2],parseFloat(res[3],10),res[4]];

}

$.effects.clip = function(o) {

	return this.queue(function() {

		// Create element
		var el = $(this), props = ['position','top','left','height','width'];

		// Set options
		var mode = $.effects.setMode(el, o.options.mode || 'hide'); // Set Mode
		var direction = o.options.direction || 'vertical'; // Default direction

		// Adjust
		$.effects.save(el, props); el.show(); // Save & Show
		var wrapper = $.effects.createWrapper(el).css({}); // Create Wrapper

		var animateTarget = el[0].tagName == 'IMG' ? wrapper : el;
		var offset = (direction == 'vertical') ? animateTarget.offset().top : animateTarget.offset().left;

		var ref = {
				size: (direction == 'vertical') ? 'height' : 'width',
				position: (direction == 'vertical') ? 'top' : 'left'
		};
		var distance = (direction == 'vertical') ? animateTarget.height() : animateTarget.width();

		if(mode == 'show') {
			animateTarget.css(ref.size, 0);
			animateTarget.css(ref.position, offset + distance/2);
		}

		// Animation
		var animation = {};

		animation[ref.size] = mode == 'show' ? distance : 0;
		animation[ref.position] = mode == 'show' ? offset : offset + distance / 2;

		// Animate
		animateTarget.animate(animation, { queue: false, duration: o.duration, easing: o.options.easing, complete: function() {
				if(mode == 'hide') el.hide(); // Hide
				$.effects.restore(el, props); $.effects.removeWrapper(el); // Restore
				if(o.callback) o.callback.apply(el[0], arguments); // Callback
				el.dequeue();
		}});

	});

};

$.fx.step.clip = function(fx) {
	if (fx.state == 0) {
		/*	handle three valid possibilities
		*	val val val val
		*	val, val, val, val
		*	val,val,val,val
		*/
			var cRE = /rect\(([0-9]{1,})(px|em)[,]? ([0-9]{1,})(px|em)[,]? ([0-9]{1,})(px|em)[,]? ([0-9]{1,})(px|em)\)/;
			fx.start = cRE.exec( fx.elem.style.clip.replace(/,/g, '') );
			//fx.end = cRE.exec( fx.end.replace(/,/g, '') );

		//	$elem = $(fx.elem);
		//	var clip = cRE.test(fx.elem.style.clip) ? fx.elem.style.clip : 'rect(0px ' + $elem.width() + 'px ' + $elem.height() + 'px 0px)';

		//alert ('foo ' + fx.start +  " " + fx.end)

		//	fx.start = cRE.exec(clip.replace(/,/g, " "));
		//		handle the fx.end error
		try {
			fx.end = cRE.exec(fx.end.replace(/,/g, ''));
		} catch (e) {
			return false;
		}
	}

	//	console.log ('foo ' + "*  " + fx.start +  " "  + "*  " +  fx.end)
	//	console.log ('bar ' + "*  " + fx.start.length +  " "  + "*  " +  fx.end.length)

	var sarr = [];
	var earr = [];
	var spos = fx.start.length
	var epos = fx.end.length
	var emOffset = fx.start[ss + 1] == 'em' ? (parseInt($(fx.elem).css('fontSize')) * 1.333 * parseInt(fx.start[ss])) : 1;

	for (var ss = 1; ss < spos; ss += 2) { sarr.push(parseInt(emOffset * fx.start[ss])); }
	for (var es = 1; es < epos; es += 2) { earr.push(parseInt(emOffset * fx.end[es])); }
	fx.elem.style.clip = 'rect(' +
	parseInt((fx.pos * (earr[0] - sarr[0])) + sarr[0]) + 'px ' +
	parseInt((fx.pos * (earr[1] - sarr[1])) + sarr[1]) + 'px ' +
	parseInt((fx.pos * (earr[2] - sarr[2])) + sarr[2]) + 'px ' +
	parseInt((fx.pos * (earr[3] - sarr[3])) + sarr[3]) + 'px)';

};

$.fx.step.borderRadius = function(fx) {

	if (fx.state == 0) {
		//alert ('here ' + fx.elem.style.borderTopLeftRadius)
		fx.start = parseInt(fx.elem.style.borderTopLeftRadius)
	}

	var r = parseInt ( fx.start + fx.pos * (fx.end - fx.start) );
	//console.log ( ' r ' + r)

	fx.elem.style.borderRadius = r+'px';

};

$.fx.step.backgroundPosition = function(fx) {
	if (!fx.bgPosReady) {
		var start = $.curCSS(fx.elem,'backgroundPosition');
		if(!start){//FF2 no inline-style fallback
			start = '0px 0px';
		}

		start = toArray(start);
		fx.start = [start[0],start[2]];
		var end = toArray(fx.end);
		fx.end = [end[0],end[2]];

		fx.unit = [end[1],end[3]];
		fx.bgPosReady = true;
	}
	//return;
	var nowPosX = [];
	nowPosX[0] = ((fx.end[0] - fx.start[0]) * fx.pos) + fx.start[0] + fx.unit[0];
	nowPosX[1] = ((fx.end[1] - fx.start[1]) * fx.pos) + fx.start[1] + fx.unit[1];
	fx.elem.style.backgroundPosition = nowPosX[0]+' '+nowPosX[1];

};

$.effects.borderReveal = function(o) {

    return this.queue(function() {

        var h = $(this).height();
        var w = $(this).width();

        var marginLeft = $(this).css('margin-left');
        var marginTop = $(this).css('margin-top');

        var borderWidth = 50;

		var delay = o.duration / 5;
		var duration = delay;
      	var slices = [];
		var el;

      	// RIGHT
  		el = $(this).clone();
  		el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

      	$(this).parent().append(el);

      	slices.push(el);

      	el.css({
			'width' : w,
			'height' : h ,
			'margin-top' : marginTop,
			'margin-left' : marginLeft,
			'clip' : 'rect('+Math.ceil(h)+'px, ' + Math.ceil(w) + 'px'	+ ', ' + Math.ceil(h) + 'px' + ', ' + Math.ceil(w-borderWidth) +'px' + ')'
		})
      	.delay(0*delay).animate({ 'clip' : 'rect('+ 0 +'px, ' + Math.ceil(w) + 'px'	+ ', ' + Math.ceil(h) + 'px' + ', ' + Math.ceil(w-borderWidth) + 'px' + ')'}, {duration: duration});


     	 // TOP
    	el = $(this).clone();
    	el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

        $(this).parent().append(el);
        slices.push(el);

        el.css({
        	'width' : w,
        	'height' : h ,
        	'margin-top' : marginTop,
        	'margin-left' : marginLeft,
        	'clip' : 'rect('+ 0 +'px, ' + Math.ceil(w-borderWidth) + 'px'	+ ', ' + borderWidth + 'px' + ', ' + Math.ceil(w-borderWidth) + 'px' + ')'})
        .delay(1*delay).animate({ 'clip' : 'rect('+ 0 +'px, ' + Math.ceil(w-borderWidth) + 'px'	+ ', ' + borderWidth + 'px' + ', ' + '0px' + ')'}, {duration: duration});


      	// LEFT
    	el = $(this).clone();
    	el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

        $(this).parent().append(el);
        slices.push(el);

        el.css({
        	'width' : w,
        	'height' : h ,
        	'margin-top' : marginTop,
        	'margin-left' : marginLeft,
        	'clip' : 'rect('+ borderWidth+'px, ' + borderWidth + 'px'	+ ', ' + borderWidth + 'px' + ', ' + 0 +'px' + ')'})
        .delay(2*delay).animate({ 'clip' : 'rect('+ borderWidth +'px, ' + borderWidth + 'px'	+ ', ' + Math.ceil(h) + 'px' + ', ' + 0 + 'px' + ')'}, {duration: duration});


        // BOTTOM
      	el = $(this).clone();
      	el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

        $(this).parent().append(el);
        slices.push(el);

        el.css({
          	'width' : w,
          	'height' : h ,
          	'margin-top' : marginTop,
          	'margin-left' : marginLeft,
          	'clip' : 'rect('+ Math.ceil(h-borderWidth) +'px, ' + Math.ceil(borderWidth) + 'px'	+ ', ' + Math.ceil(h) + 'px' + ', ' + borderWidth + 'px' + ')'})
          .delay(3*delay).animate({	'clip' : 'rect('+ Math.ceil(h-borderWidth) +'px, ' + Math.ceil(w-borderWidth) + 'px'	+ ', ' + Math.ceil(h) + 'px' + ', ' + borderWidth + 'px' + ')'}, {duration: duration})



        // MIDDLE
      	el = $(this).clone();
      	el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

        $(this).parent().append(el);
        slices.push(el);

        el.css({
          	'width' : w,
          	'height' : h ,
          	'margin-top' : marginTop,
          	'margin-left' : marginLeft,
          	'clip' : 'rect('+ borderWidth +'px, ' + Math.ceil(w-borderWidth) + 'px'	+ ', ' + Math.ceil(h-borderWidth) + 'px' + ', ' + Math.ceil(w-borderWidth) + 'px' + ')'})
          .delay(4*delay).animate({	'clip' : 'rect('+ borderWidth +'px, ' + Math.ceil(w-borderWidth) + 'px'	+ ', ' + Math.ceil(h-borderWidth) + 'px' + ', ' + borderWidth + 'px' + ')'}, {duration: duration})


     	//el = null;


      	this.removeSlices = removeSlices;
     	this.slices = slices;
     	this.callback = o.callback;

      	$(this).css({'opacity' : '0'}).delay( o.duration).queue(  this.removeSlices );
      	$(this).dequeue();

      	slices = null;
    });
};

$.effects.checkerBoard = function(o) {
	return this.queue(function() {
		var h = $(this).height();
		var w = $(this).width();

		var marginLeft = $(this).css('margin-left');
		var marginTop = $(this).css('margin-top');

		var boxSize = 100;
		var boxSizeHalf = boxSize/2;

		var hSliceCount = Math.ceil( h / boxSize);
		var wSliceCount = Math.ceil( w / boxSize);


		var delay = .25*1000;

		do {

			delay /= 2;

			var duration = o.duration - (wSliceCount * delay);

		} while (duration < 250)

		var slices = [];

		for (var i=0; i<wSliceCount; i++)
		{
			for (var j=0; j<hSliceCount; j++)
			{
				var el = $(this).clone();
				el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

				$(this).parent().append(el);
				slices.push(el);

				var boxDelay = j * delay;

				/*if (j%2){
					boxDelay = i*delay;
				} else {
				  boxDelay = (wSliceCount-i)*delay;
				}
				*/
				el.css({
					'width' : w,
					'height' : h ,
					'margin-top' : marginTop,
					'margin-left' : marginLeft,
					'clip' : 'rect('+ Math.floor( j*boxSize + boxSizeHalf ) +'px, ' + Math.ceil(i*boxSize + boxSizeHalf) + 'px'	+ ', ' + Math.floor(j*boxSize + boxSizeHalf) + 'px' + ', ' + Math.ceil(i*boxSize + boxSizeHalf) + 'px' + ')'})
					.delay(boxDelay)
					.animate({'clip' : 'rect('+ Math.floor( j*boxSize ) +'px, ' + Math.ceil(i*boxSize + boxSize) + 'px'	+ ', ' + Math.ceil(j*boxSize + boxSize) + 'px' + ', ' + Math.floor(i*boxSize) + 'px' + ')'}, {duration: duration})

				//_.log(boxDelay*8.5);
			}
		}

		this.removeSlices = removeSlices;
		this.slices = slices;
		this.callback = o.callback;

		$(this).css({'opacity' : '0'}).delay( o.duration*4).queue(  this.removeSlices );
		$(this).dequeue();


		slices = null;
	});
}


removeSlicesBubbleReveal = function() {

	$(this).unwrap('div.temp');

	removeSlices.apply(this);

};

removeSlices = function() {

	var slices = this.slices;

	for (var i=0, len = slices.length; i<len; i++) {

		slices[i].remove();
		slices[i] = null;

	}

	this.removeSlices = null;
	this.slices = null;

	$(this).css({'opacity' : '1', 'display': 'block'});
	$(this).dequeue();

	this.callback.call();

	this.callback = null;

	return this;

};

$.effects.bubbleReveal = function(o) {

	return this.queue(function() {

		var $this = $(this);

		var h = $this.height();
		var w = $this.width();

		var marginLeft = ($this.css('margin-left'));
		var marginTop = ($this.css('margin-top'));

		var area = w*h;
		var boxSize = area > 1000000 ? area * 100 / 1000000 : 100;

		var boxSizeHalf = boxSize/2;

		var hSliceCount = Math.ceil( h / boxSize);
		var wSliceCount = Math.ceil( w / boxSize);

		var offsetX = 0;//$(this).parent().position().left;// boxSizeHalf - (w % boxSize);
		var offsetY = 0;//$(this).parent().position().top;//h % boxSize;

		var delay = .25*1000;

		do
		{
			delay /= 2;
			var duration = o.duration - (hSliceCount * delay);
		}
		while (duration < 250)

			var src = $this.attr('src');

		$this.wrap('<div class="temp">');

		var parent = $this.parent();
		parent.css({
			'width': w,
			'height': h,
			'position' : 'absolute',
			'overflow' : 'hidden',
			'clip' : 'rect('+ 0 +'px, ' + Math.ceil((w)) + 'px'	+ ', ' + Math.ceil((h)) + 'px' + ', ' + 0 + 'px' + ')',
			'margin-top' : marginTop,
			'margin-left' : marginLeft
		});


	  	var slices = [];

	  	for (var i=0; i<wSliceCount; i++)
	  	{
		  	for (var j=0; j<hSliceCount; j++)
		  	{


				var el = $('<div>'); //$(this).clone();
				el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden'});

				parent.append(el);
				slices.push(el);

				var boxDelay = j* delay;


				el.css({
					'borderRadius' : boxSizeHalf,
					'background-image' : 'url(' + src + ')',
					'width' : 0,
					'height' : 0,
					'top' : j*boxSize + boxSizeHalf + offsetY,
					'left' : i*boxSize + boxSizeHalf + offsetX,
					'background-position' : Math.floor(-i*boxSize -boxSizeHalf) + 'px' + ' ' + Math.floor(-j*boxSize -boxSizeHalf) + 'px',
					'background-size' : w + 'px ' + h + 'px'
				}).delay(boxDelay).animate({
					'width' : boxSize,
					'height' : boxSize,
					'top' : j*boxSize + offsetY,
					'left' : i*boxSize + offsetX,
					'background-position' : Math.floor(-i*boxSize) + 'px' + ' ' + Math.floor(-j*boxSize) + 'px'
				}, {'duration' : duration* .5,
					'easing' : 'linear'/*,
					'complete' : function ()
					{
						//console.log ('fin')
					}
				*/
				}).animate({'borderRadius' : 0}, 	{	duration: duration * .5, easing: 'linear'		});

		  	}
	  	}

	  	this.removeSlices = removeSlicesBubbleReveal;
	  	this.slices = slices;
	  	this.callback = o.callback;

	  	$this.css({'opacity' : '0'}).delay( o.duration).queue(  this.removeSlices );
	  	$this.dequeue();

	  	slices = null;
	});

};

$.effects.barHorizontalRandomReveal = function(o) {

	return this.queue(function() {

		var h = $(this).height();
		var w = $(this).width();

		var marginLeft = $(this).css('margin-left');
		var marginTop = $(this).css('margin-top');

		var sliceCount = Math.ceil( h / 100);
		if (sliceCount < 5) sliceCount = 5;

		var delay = .25*1000;

		do
		{
			delay /= 2;
			var duration = o.duration - (sliceCount * delay);
		}
		while (duration < 250)



	  	var slices = [];
	  	var normalizedSliceHeight = h / sliceCount;

	  	var edgeCoordinates = [];
	  	var delays = [];

	  	edgeCoordinates[0] = 0;
	  	delays[0] = 0;
	  	for (var i=1; i<sliceCount; i++)
	  	{
		  	edgeCoordinates[i] = normalizedSliceHeight*(i-1) + (Math.random() * normalizedSliceHeight);
		 	delays.push(i)
	  	}
	  	edgeCoordinates.push(h);

	  	delays = _.shuffle(delays);

	  	var midpointCoordinates = [];
	  	var sliceHeights = [];
	  	var startCoordinates = [];
	  	for (var i=0; i<sliceCount; i++)
	  	{
			sliceHeights[i] = edgeCoordinates[i+1] - edgeCoordinates[i];
			midpointCoordinates[i] = edgeCoordinates[i] + .5 * sliceHeights[i];
			startCoordinates[i] = Math.max(Math.random() * h - h* 3/2, 0);


		  	var el = $(this).clone();
			el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

			$(this).parent().append(el);
			slices.push(el);


			el.css({
				'width' : w,
				'height' : h ,
				'margin-top' : marginTop,
				'margin-left' : marginLeft,
				'clip' : 'rect('+ Math.floor(startCoordinates[i]) +'px, ' + Math.ceil(w) + 'px'	+ ', ' + Math.floor(startCoordinates[i]) + 'px' + ', ' + 0 + 'px' + ')'})
			.delay(delays[i]*delay).animate({
				'clip' : 'rect('+ Math.floor(edgeCoordinates[i]) +'px, ' + Math.ceil(w) + 'px'	+ ', ' + Math.ceil(edgeCoordinates[i+1]) + 'px' + ', ' + '0px' + ')'}, {duration: duration})

	  	}

	  	this.removeSlices = removeSlices;
	  	this.slices = slices;
	  	this.callback = o.callback;

	 	 $(this).css({'opacity' : '0'}).delay( o.duration).queue(  this.removeSlices );
	  	$(this).dequeue();

	  slices = null;
	});

};

$.effects.blinds = function(o) {

    return this.queue(function() {

        var h = $(this).height();
        var w = $(this).width();

        var marginLeft = $(this).css('margin-left');
        var marginTop = $(this).css('margin-top');


      	var strips;
      	var stripHeight = 50;

      	do {
    	  	stripHeight *= 2;
    	  	strips = Math.ceil(h/stripHeight);
      	}
      	while (strips > 20)

      	while (strips < 7)
      	{
    	  	stripHeight /= 2;
    	  	strips = Math.ceil(h/stripHeight);
      	}

      	var halfStrip = stripHeight/2;
      	var top = 0;
      	var bottom = stripHeight;

      	var delay = .25*1000;

      	do
      	{
      		delay /= 2;
          	var duration = o.duration - (strips * delay);
      	}
      	while (duration < 250)

      	var slices = [];

      	for (var i=0; i<strips; i++)
      	{
    		var el = $(this).clone();
    		el.css({'position': 'absolute', 'display':'block', 'overflow': 'hidden', 'opacity': 1});

        	$(this).parent().append(el);
        	slices[i] =(el);

			el.css({
				'width' : w,
				'height' : h ,
				'margin-top' : marginTop,
				'margin-left' : marginLeft,
				'clip' : 'rect('+Math.ceil(top+halfStrip)+'px, ' + Math.ceil(w) + 'px'	+ ', ' + Math.ceil(bottom-halfStrip) + 'px' + ', ' + '0px' + ')'})
			.delay(i*delay).animate({
        	'clip' : 'rect('+Math.ceil(top)+'px, ' + Math.ceil(w) + 'px'	+ ', ' + Math.ceil(bottom) + 'px' + ', ' + '0px' + ')'}, {duration: duration})

			top += stripHeight;
			bottom += stripHeight;

			el = null;
      	}

      	this.removeSlices = removeSlices;
      	this.slices = slices;
      	this.callback = o.callback;

      	$(this).css({'opacity' : '0'}).delay( o.duration).queue(  this.removeSlices );
      	$(this).dequeue();

      	slices = null;

    });

};
}).call(global, module, undefined, undefined);
