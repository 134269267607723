
; require("/srv/www/html/system/apps/portfolio/src/js/slideshow.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */
/* global _: false */

(function(api, $, undefined) {

	var dom = {},

		settings = {},

		state = {},

		setupDom = function() {

			dom = {
				window: core.dom.getElement({key: "window"}),
				document: core.dom.getElement({key: "document"}),
				imageTarget: settings.imageTarget
			};

		},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupInitialState = function() {

			state = {
				imagePositions: [],
				numModulesToLoad: 0,
				numModulesLoaded: 0
			};

		},

		haveAllModulesLoaded = function() {

			return state.numModulesLoaded === state.numModulesToLoad;

		},

		setupModules = function() {

			var modules = ["controlSlider", "captions", "autoplay", "images"];

			if (settings.showThumbs) {

				modules.push("controlThumbs");

			}

			if (settings.showButtons) {

				modules.push("controlButtons");

			}

			state.numModulesToLoad = modules.length;

			slideshow.loadModules(modules);

		},

		addEventHandlers = function() {

			dom.document.on("slideshow_module_ready.reel", function() {

				state.numModulesLoaded++;

				if (haveAllModulesLoaded()) {

					dom.document.off("slideshow_module_ready.reel");

					// Wait to init the plugin until all other modules have loaded their
					// markup into the DOM. The plugin will wrap all the markup inside the
					// imageTarget.
					slideshow.controlSlider.initPlugin();

					// Move the control container so it is constrained to the content area,
					// not the full width of the plugin container.
					// if (settings.showThumbs) {
					if (settings.showButtons) {

						slideshow.controlButtons.moveContainer(dom.imageTarget);

					}

					slideshow.triggerModuleReady("reel");

				}

			});

			dom.document.on("slideshow_images_scaled.reel", function(e) {

				positionWrappers();

				if (e.changedBy === "resize") {

					// Slide to the new position of the active image.
					core.triggerCustomEvent({
						type: "slideshow_slider_slide_to",
						position: getContentPositionByImgIdx(e.activeImgObj.index),
						changedBy: e.changedBy
					});

				}

			});

			dom.document.on("slideshow_active_image_updated.reel", function(e) {

				if (e.changedBy !== "startup") {

					slideshow.images.preloadImages({
						targetIdx: e.activeImgObj.index
					});

					core.triggerCustomEvent({
						type: "slideshow_slider_slide_to",
						position: getContentPositionByImgIdx(e.activeImgObj.index),
						changedBy: e.changedBy
					});

				}

			});

			dom.document.on("slideshow_slider_dragger_released.reel slideshow_slider_rail_clicked.reel", function(e) {

				var position,
					contentPosition,
					imgIdx;

				if (e.type === "slideshow_slider_dragger_released") {

					position = e.draggerLeft;

					if (position + e.draggerWidth === e.railWidth) {

						// The dragger is all the way to the right edge of the rail.
						// Force the last image to be selected.

						position = e.fullContentWidth;

					}

				} else {

					position = e.offsetX;

				}

				contentPosition = getContentPositionByTargetPosition(position, e.fullContentWidth);

				imgIdx = getImgIdxByContentPosition(contentPosition, e.fullContentWidth);

				core.triggerCustomEvent({
					type: "slideshow_control_fired",
					controlType: "slider",
					activeIdx: imgIdx
				});

			});

		},

		getContentPositionByImgIdx = function(imgIdx) {

			return state.imagePositions[imgIdx];

		},

		getContentPositionByTargetPosition = function(targetPosition, fullContentWidth) {

			var contentPosition,
				draggerContainerWidth = dom.imageTarget.width(),
				// Data stored by plugin.
				scrollAmount = dom.imageTarget.data("scrollAmount");

			contentPosition = targetPosition * scrollAmount;


			return contentPosition;

		},

		getImgIdxByContentPosition = function(position, fullContentWidth) {

			var sortedIndex = _.sortedIndex(state.imagePositions, position),
				wrapperWidth,
				cutoff,
				pixelsPassedStart,
				numImgs = state.imagePositions.length;

			if (sortedIndex > 0 && state.imagePositions[sortedIndex] !== position) {

				if (sortedIndex < numImgs) {

					wrapperWidth = state.imagePositions[sortedIndex] - state.imagePositions[sortedIndex - 1];

				} else {

					// This position is after the beginning of the last image.

					wrapperWidth = fullContentWidth - state.imagePositions[sortedIndex - 1];

				}

				cutoff = wrapperWidth / 2;

				pixelsPassedStart = position - state.imagePositions[sortedIndex - 1];

				if (pixelsPassedStart < cutoff || sortedIndex === numImgs) {

					sortedIndex--;

				}

			}

			return sortedIndex;

		},

		positionWrappers = function() {

			$.each(settings.images, function(i, el) {

				sizeWrapper(el);
				positionWrapper(el);

			});

			// Now that we are done sizing and positioning, show the wrappers.
			// We have to wait to show them until after the loop ends, otherwise
			// the browser dies a horrible death.
			// core.dom.getElement({key: "imageWrappers", refresh: true}).show();

		},

		getWrapperWidth = function($wrapper, imgWidth) {

			return imgWidth + slideshow.images.getBorderPadSize($wrapper, true, "width");

		},

		getWrapperHeight = function($wrapper, imgHeight) {

			if (slideshow.hasReflection()) {

				return dom.imageTarget.height();

			} else {

				return imgHeight + slideshow.images.getBorderPadSize($wrapper, true, "height");

			}

		},

		sizeWrapper = function(imgObj) {

			var $wrapper  = imgObj.wrapper,
				// wrapperWidth = imgObj.scaledImgDimensions.width,
				wrapperWidth = getWrapperWidth(imgObj.wrapper, imgObj.scaledImgDimensions.width),
				// wrapperHeight = dom.imageTarget.height();
				wrapperHeight = getWrapperHeight(imgObj.wrapper, imgObj.scaledImgDimensions.height);

			$wrapper.css({
				width: wrapperWidth,
				height: wrapperHeight
			});

			imgObj.scaledWrapperDimensions = {
				width: wrapperWidth,
				height: wrapperHeight
			};

		},

		positionWrapper = function(imgObj) {

			var $wrapper = imgObj.wrapper,
				wrapperHeight = imgObj.scaledWrapperDimensions.height,
				halfWrapperHeight = wrapperHeight / 2,
				mainImgHeight = imgObj.scaledImgDimensions.height,
				$mainImgWrapper = imgObj.mainImgWrapper,
				$mainImg = imgObj.largeImage,
				$reflectionImg = imgObj.reflectionImage,
				mainImgHeightOffset = imgObj.scaledImgDimensions.heightOffset === undefined ? 0 : imgObj.scaledImgDimensions.heightOffset,
				halfMainImgHeight = mainImgHeight / 2,
				isFirstImg = imgObj.index === 0,
				prevImgObj,
				prevWidth,
				prevPosition;

			if (isFirstImg) {

				state.imagePositions[0] = 0;

			} else {

				prevImgObj = settings.images[imgObj.index - 1];

				prevWidth = prevImgObj.scaledWrapperDimensions.width;

				prevPosition = state.imagePositions[prevImgObj.index];

				state.imagePositions[imgObj.index] = prevPosition + prevWidth;

			}

			if (slideshow.hasReflection()) {

				$mainImgWrapper.css({
					bottom: settings.reflectionHeight
				});

				if (mainImgHeightOffset <= 0) {

					// The entire image fits within our space, so just
					// align it to the bottom.

					$mainImg.css({
						bottom: 0,
						top: "auto",
						marginTop: "auto"
					});

					$reflectionImg.css({
						top: 0
					});

				} else {

					// The image is larger than our space and is cropped, so
					// adjust it to be vertically centered.

					$mainImg.css({
						bottom: "auto",
						top: "50%",
						marginTop: -halfMainImgHeight
					});

					$reflectionImg.css({
						top: -mainImgHeightOffset
					});

				}

			} else {

				/*$mainImg.css({
					top: (imageTargetHeight - mainImgHeight) / 2
				});*/

				$wrapper.css({
					top: "50%",
					marginTop: -halfWrapperHeight
				});

			}

		},

		destroy = function() {

			dom.document.off(".reel");

		};

	api.init = function() {

		saveSettings();

		setupDom();

		setupInitialState();

		addEventHandlers();

		setupModules();

		slideshow.addCleanupItem(destroy);

	};

}(window.slideshow.reel = window.slideshow.reel || {}, jQuery));
}).call(global, module, undefined, undefined);
