; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
/* global core: false */
/* global colorManager: false */
/* global slideshow: false */
/* global template: false */
/* global _: false */

(function(api, $, undefined) {

	var dom = {},

		state = {},

		saveSettings = function(controlPanelSettings) {

			var templateSettings = getTemplateSettings();

			// Setup default settings.
			api.settings = {
				galleryType: "standard",
				thumbType: "image",
				thumbSize: "thumb",
				thumbNumbers: false,
				thumbOverlay: false,
				thumbImageTips: false,
				thumbOrientation: "vertical",
				controlTheme: 1,
				mainControlColorKey: "template_pagebgcolor",
				thumbControlColorKey: "template_pagebgcolor",
				captionControlColorKey: "menu_bgcolor",
				captionTarget: "#content",
				hasFillSupport: true,
				hasFitSupport: true,
				hasReflection: false,
				reflectionHeight: 0,
				effect: "fade",
				effectDuration: 1000,
				delay: 1000,
				showThumbs: false,
				showButtons: false,
				showSlider: false,
				showSliderProgressBar: false,
				autoDraggerLength: true,
				autoplay: true,
				imageTarget: core.dom.getElement({key: "contentWrapper"}),
				isTouchDevice: core.util.isTouch()
			};

			if(controlPanelSettings.showButtons || controlPanelSettings.showThumbs) {

				api.settings.showSlider = true;

			}

			$.extend(true, api.settings, controlPanelSettings, templateSettings);

			// If a template overrides "effect", by default it also override "initialEffect".
			// However, templates still have the option of independently overriding "initialEffect".
			api.settings.initialEffect = api.settings.initialEffect ? api.settings.initialEffect : api.settings.effect;

			// Templates can setup a site wide gallery type, but certain page types can
			// override this value. Currently, info and contact will always be standard.
			api.settings.galleryType = api.settings.galleryTypeOverride ? api.settings.galleryTypeOverride : api.settings.galleryType;

			api.settings.controlThemeClass = "control-theme-" + api.settings.controlTheme;

		},

		setupInitialState = function() {

			state = {
				isTransitionInProgress: false,
				numModulesToLoad: 0,
				numModulesLoaded: 0,
				cleanupItems: []
			};

		},

		setupDom = function() {

			dom = {
				document: core.dom.getElement({ key: "document" }),
				body: core.dom.getElement({key: "body"})
			};

		},

		addCssHooks = function() {

			dom.body
				.removeClass("image_count_0")
				.addClass("image_count_" + api.getImgCount())
				.addClass(api.settings.showThumbs ? "thumbs_visible" : "thumbs_invisible")
				.addClass(api.settings.showSlider ? "slider_visible" : "slider_invisible")
				.addClass(api.settings.thumbImageTips ? "thumb_tips" : "")
				.addClass("gallery_type_" + api.settings.galleryType)
				.addClass(api.hasReflection() ? "reflection" : "");

		},

		haveAllModulesLoaded = function() {

			return state.numModulesLoaded === state.numModulesToLoad;

		},

		addEventHandlers = function() {

			dom.document.on("slideshow_module_ready.slideshow", function() {

				state.numModulesLoaded++;

				if (haveAllModulesLoaded()) {

					core.triggerCustomEvent({
						type: "page_ready",
						pageType: api.settings.pageType
					});

				}

			});

			dom.document.on("slideshow_transition_start.slideshow", function() {

				state.isTransitionInProgress = true;

			});

			dom.document.on("slideshow_transition_complete.slideshow", function() {

				state.isTransitionInProgress = false;

			});

		},

		initGallery = function() {

			switch (api.settings.galleryType) {

				case "standard":
					slideshow.standard.init();
					break;

				case "reel":
					slideshow.reel.init();
					break;

				case "grid":
					slideshow.grid.init();
					break;

			}

		},

		getTemplateSettings = function() {

			return template.data.slideshow;

		},

		destroy = function() {

			dom.document.off(".slideshow");

		};

	api.settings = {};

	api.loadModules = function(modules) {

		// Add 1 to track the loading of the gallery type module, i.e. standard, reel etc.
		state.numModulesToLoad = modules.length + 1;

		$.each(modules, function(index, module) {

			slideshow[module].init();

		});

	};

	api.getColorThemeClass = function(color) {

		return _.blackOrWhite(colorManager.getColor(color).toString()) === "black" ? "dark" : "light";

	};

	api.isTransitionInProgress = function() {

		return state.isTransitionInProgress;

	};

	api.getImgCount = function() {

		return api.settings.images.length;

	};

	api.getImgUrl = function(idx, size){

		return api.settings.images[idx][size];

	};

	api.hasFillSupport = function() {

		return api.settings.hasFillSupport;

	};

	api.hasFitSupport = function() {

		return api.settings.hasFitSupport;

	};

	api.hasReflection = function() {

		return api.settings.hasReflection;

	};

	api.isCPanelSettingFit = function() {

		return api.settings.scaleMode === "fit";

	};

	api.isCPanelSettingFill = function() {

		return api.settings.scaleMode === "fill";

	};

	api.isReelGalleryType = function() {

		return api.settings.galleryType === "reel";

	};

	api.isStandardGalleryType = function() {

		return api.settings.galleryType === "standard";

	};

	api.isTouch = function() {

		return api.settings.isTouchDevice;

	};

	api.triggerModuleReady = function(mod) {

		core.triggerCustomEvent({
			type: "slideshow_module_ready",
			module: mod
		});

	};

	api.addCleanupItem = function(callback) {

		state.cleanupItems.push(callback);

	};

	api.runCleanup = function(duration) {

		var promises = [];

		// Handles initial site load (cleanupItems is undefined at that time).
		if (state.cleanupItems && state.cleanupItems.length) {

			$.each(state.cleanupItems, function(index, callback) {

				promises.push($.when(callback(duration)));

			});

			state.cleanupItems = [];

		}

		return $.when.apply(null, promises);

	};

	api.init = function(controlPanelSettings) {

		saveSettings(controlPanelSettings);

		setupDom();

		setupInitialState();

		addCssHooks();

		addEventHandlers();

		initGallery();

		api.addCleanupItem(destroy);

	};

}(window.slideshow = window.slideshow || {}, jQuery));
; browserify_shim__define__module__export__(typeof slideshow != "undefined" ? slideshow : window.slideshow);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
