; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
window.modal = {

	dom: {
		wrapper: undefined
	},

	open: function(url, width, height, caption) {

		modal.dom.wrapper = $('<div id="modal">').appendTo('body');

		var boxHeight = height + (parseInt(modal.dom.wrapper.css("padding-top")) * 2),
			offset = ($(window).height() - boxHeight) / 2;

		modal.dom.wrapper.css('width', width).css({
			'margin-left': '-' + (modal.dom.wrapper.outerWidth() / 2) + 'px',
			'height': height,
			'top': offset
		});

		$('<div id="modalCloseWrapper"><a href="#" id="modalClose" title="Close"><img src="images/close_x.png" alt=""></a></div><div id="modalContent"></div>')
		.appendTo(modal.dom.wrapper);

		$('#modalClose').click(modal.close);

		if (caption) {
			$('<div id="modalCaption">').html(caption).appendTo(modal.dom.wrapper);
		}

		$('<iframe />', {
			scrolling: 'auto',
			frameborder: '0',
			src: url
		}).css({
			width: width,
			height: height
		}).appendTo($('#modalContent'));

		modal.dom.wrapper.jqm({
			modal: false,
			overlay: 70,
			toTop: true,
			onHide: function(hash) {
				hash.w.remove();
				hash.o.remove();
				modal.dom.wrapper = undefined;
			},
			onShow: function(hash) {

				// Need this code to fix unnecessary scroll bars appearing in Chrome.
				// Basically have to change the dimensions of the iframe in order
				// for Chrome to repaint the screen and realize that it doesn't need
				// the scroll bars.

				var $iframe = hash.w.find("#modalContent > iframe"),
					originalWidth = parseInt($iframe.css("width"), 10);

				hash.w.show();

				$iframe.css("width", originalWidth+50);

				setTimeout((function($el, width) {

					return function() {
						$el.css("width", width);
					};

				})($iframe, originalWidth), 500);

			}
		}).jqmShow();

		return false;

	},

	close: function() {

		modal.dom.wrapper.jqmHide();

		return false;

	}

};
; browserify_shim__define__module__export__(typeof modal != "undefined" ? modal : window.modal);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
