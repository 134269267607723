'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var Galleria = (typeof window !== "undefined" ? window.Galleria : typeof global !== "undefined" ? global.Galleria : null);

var theme = require('./theme.js');

var isInited = false;

var fullscreenSlideshow = function(opts) {

	var dom;
	var options;

	var getDefaults = function() {

		return {
			target: '#fullscreenSlideshow',
			data: []
		};

	};

	var launch = function(target, musicIsPaused) {

		// Open galleria slideshow.

		Galleria.run(options.target + ' .fullscreen-slideshow__target', {
			dataSource: options.data,
			show: target,
			autoplay: options.isAutoPlay,
			// Pass in custom options.
			_musicIsPaused: musicIsPaused,
			_hasMusic: options.hasMusic,
			_showControls: options.showControls
		});

	};

	var exit = function() {

		Galleria.get(0).exitFullscreen();

	};

	// Provide a way to dynamically update the slideshow data so we can support
	// multiple separate galleries/image grids on a single page that each have
	// their own fullscreen slideshow
	var setData = function(data) {

		options.data = data;

	};

	var setupDom = function() {

		dom = {};

		dom.fullscreenSlideshow = $(options.target);

	};

	var destroy = function() {

		dom = null;
		options = null;

	};

	var initialize = function() {

		options = getDefaults();
		$.extend(options, opts);
		setupDom();
		// Copy over module config.
		$.extend(options, dom.fullscreenSlideshow.data('fullscreen-slideshow-options'));

	};

	initialize();

	return {
		destroy: destroy,
		launch: launch,
		exit: exit,
		setData: setData
	};

};

var addEventHandlers = function() {

	Galleria.ready(function() {

		this.enterFullscreen();

	});

};

// Due to the weird initialization process involving the global Galleria object, this method is intended to only be called ONCE per page load!
var init = function() {

	if (isInited) {

		return;

	}

	isInited = true;

	// https://code.google.com/p/chromium/issues/detail?id=467934
	// Chrome on Yosemite is telling Galleria that this is a touch device.
	// Hardcode this value to false to fix. This makes this a desktop only
	// module in essence. If this module needs to work on mobile, other
	// alternatives will need to be researched.
	Galleria.TOUCH = false;

	theme.add();
	addEventHandlers();

};

// Export a module init method and a factory function to support multiple fullscreen-slideshow elements on a single page (necessary to support full post layout on blog pages, which could have multiple blog posts on a single page, with each blog post supporting a fullscreen-slideshow)
module.exports = {
	init: init,
	// Deprecated factory() method in favor of create()
	factory: fullscreenSlideshow,
	create: fullscreenSlideshow
};
