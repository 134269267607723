'use strict';

var dom = require('form-widget-dom');
var settings = require('form-widget-settings');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');

var $datepickers;

var addEventHandlers = function() {

	emitter.on('fbWidget-layout-adjusted', function() {

		if ($datepickers.length) {

			$datepickers.datepicker('hide');

		}

	});

	emitter.on('fbWidget-destroy', function() {

		if ($datepickers.length) {

			$datepickers.datepicker('destroy');
			$datepickers = null;

		}

	});

};

var getJqueryUIDateFormat = function($el) {

	return $el.attr('data-format');

};

module.exports = {

	init: function() {

		var $inputs = $('input[type="date"]');

		$datepickers = $();

		if (settings.useJqueryUIDatepicker) {

			addEventHandlers();

			$inputs.each(function(i) {

				var $this = $inputs.eq(i);

				$this
					.attr({
						type: 'text',
						// Trigger validation when a date is selected from the
						// picker or manually entered.
						'data-parsley-trigger': 'change'
					})
					.datepicker({
						showOtherMonths: true,
						prevText: '<<',
						nextText: '>>',
						dateFormat: getJqueryUIDateFormat($this)
					});

				$datepickers = $datepickers.add($this);

			});

			$('#ui-datepicker-div').addClass('fb-datepicker');

		} else {

			// Using native mobile date picker, remove unnecessary validation.

			$inputs.each(function(i) {

				var $this = $inputs.eq(i);

				$this
					.removeAttr('data-parsley-dateformat')
					.removeAttr('data-parsley-validdate');

			});

		}

	},

	isDateType: function($col) {

		return $col.hasClass('fb-date-type');

	},

	getVal: function($col) {

		var name = dom.getFieldTitleText($col);
		var $input = dom.getFieldInputs($col);
		var val = $input.val();
		var d;

		if (val && !settings.useJqueryUIDatepicker) {

			// Reformat the native mobile date to match user's setting.

			val = $.datepicker.parseDate('yy-mm-dd', val);
			val = $.datepicker.formatDate(getJqueryUIDateFormat($input), val);

			// d = new Date(val);

			// Adjust date for timezone.
			// d.setTime(d.getTime() + d.getTimezoneOffset()*60*1000);

			// Format the date in case value came from mobile date picker.
			// val = $.datepicker.formatDate(getJqueryUIDateFormat($input), d);

		}

		return {
			name: name,
			value: val
		};

	}

};