'use strict';

var dom = require('form-widget-dom');
var emitter = require('emitter');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var scrollToElement = require('scroll-to-element');

var state = {
	cloneId: 0,
	cloneCnt: 0
};

var isActionAdd = function($el) {

	return $el.attr('data-action') === 'add';

};

var hideAddAction = function($el) {

	$el.find('[data-action="add"]').addClass('hide');

};

var showAddAction = function($el) {

	$el.find('[data-action="add"]').removeClass('hide');

};

var hideRemoveAction = function($el) {

	$el.find('[data-action="remove"]').addClass('hide');

};

var showRemoveAction = function($el) {

	$el.find('[data-action="remove"]').removeClass('hide');

};

var getFamilyFriendsRows = function() {

	return dom.rows.has('.fb-familyfriends-type');

};

var addRemoveFamilyFriends = function() {

	var $this = $(this);
	var $row = $this.closest('.fb-row');
	var $clone;
	var $select;
	var $ffRows = getFamilyFriendsRows();
	var $lastRow = $ffRows.last();

	if (isActionAdd($this)) {

		// Add clone.

		$clone = $row.clone();
		$select = $clone.find('select');

		// Flag as a clone.
		$clone.addClass('fb-ff-clone');

		// Hide "add" action from cloned row.
		hideAddAction($row);

		if (state.cloneCnt === 0) {

			// Show "remove" action on cloned row.
			showRemoveAction($row);

			// Show "remove" action on clone.
			showRemoveAction($clone);

		}

		// Update title labels.
		$clone.find('label').each(function() {

			var $this = $(this);
			var forAttr = $this.attr('for');

			$this.attr('for', forAttr + '-clone-' + state.cloneId);

		});

		// Update form fields.
		$clone.find('input').add($select).each(function() {

			var $this = $(this);
			var oldAttr = $this.attr('id');
			var newAttr = oldAttr + '-clone-' + state.cloneId;

			$this
				.attr('id', newAttr)
				.attr('name', newAttr);

			// Make clones optional.
			// $this.prev().removeClass('fb-is-required');

			if (!$this.is('select')) {

				$this.val('');

			}

		});

		$clone
			.css({
				opacity: 0
			})
			.insertAfter($row);

		scrollToElement({
			element: $clone,
			force: true
		});

		$clone.animate({opacity: 1});

		emitter.emit('fbWidget-familyfriends-field-added', {
			$row: $clone,
			$select: $select
		});

		dom.triggerPublicFormEvent({
			type: 'fbWidget-familyfriends-field-added'
		});

		state.cloneId++;
		state.cloneCnt++;

	} else {

		// Remove clone.

		emitter.emit('fbWidget-familyfriends-field-remove', {
			$row: $row
		});

		$row.animate({opacity: 0}, function() {

			var removingLastRow = $row.is($lastRow);
			var $newLastRow = $lastRow;

			if (removingLastRow) {

				$newLastRow = $ffRows.eq(state.cloneCnt-1);

			}

			// Show "add" action on new last row.
			showAddAction($newLastRow);

			if (state.cloneCnt === 1) {

				// Hide "remove" action when only one row.
				hideRemoveAction($newLastRow);

			}

			$row.remove();

			emitter.emit('fbWidget-familyfriends-field-removed', {
				$row: $row
			});

			dom.triggerPublicFormEvent({
				type: 'fbWidget-familyfriends-field-removed'
			});

			state.cloneCnt--;

		});

	}

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-familyfriends');

	dom.fbForm.on('click.fb-familyfriends', '.fb-ff-action', addRemoveFamilyFriends);

};

module.exports = {

	init: function() {

		addEventHandlers();

	},

	isFamilyfriendsType: function($col) {

		return $col.hasClass('fb-familyfriends-type');

	},

	getVal: function($col) {

		var $els = dom.getFieldInputs($col);
		var name = dom.getFieldTitleText($col);
		var vals = [];

		$els.each(function(i) {

			var $el = $els.eq(i);

			vals.push({
				name: dom.getNestedFieldTitleText($el),
				value: $el.val()
			});

		});

		return {
			name: name,
			value: vals
		};

	}

};