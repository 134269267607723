'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var dom = require('form-widget-dom');

var defaultText;

var getInfo = function($fileInput) {

	var $fieldContainer = dom.getFieldContainer($fileInput);

	return $fieldContainer.find('.fb-file-upload-info');

};

var clearNativeFile = function($fileInput) {

	$fileInput.val('');

};

var clearCustomFile = function($fileInput) {

	getInfo($fileInput).html(defaultText);

};

var clearFile = function($fileInput) {

	clearNativeFile($fileInput);
	clearCustomFile($fileInput);

};

var getFile = function($fileInput) {

	return $fileInput[0].files[0];

};

var showFileName = function($fileInput) {

	getInfo($fileInput).html('(' + getFile($fileInput).name + ')');

};

var isFileSelected = function($fileInput) {

	return !!$fileInput[0].files.length;

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-fileupload');

	dom.fbForm.on('click.fb-fileupload', '.fb-file-upload-container', function() {

		var $fieldContainer = dom.getFieldContainer($(this));

		$fieldContainer.find('.fb-file-upload').click();

	});

	dom.fbForm.on('change.fb-fileupload', '.fb-file-upload', function() {

		// Clear file info if user clicks cancel from file picker dialog.

		console.log('change event fired...', isFileSelected($(this)));

		var $this = $(this);

		// Parsley has some issues validating this field after a UI reset, so
		// manually trigger a validation on every change event.
		$this.parsley().validate();

		if (!isFileSelected($this)) {

			clearFile($this);

		}

	});

	dom.fbForm.on('reset.fb-fileupload', function() {

		var $fileInputs = dom.fbForm.find('[type="file"]');

		// All browsers except IE reset file input fields when a form is
		// natively reset. To fixe IE, we have to manually reset the values.
		// Unfortunately, IE is also the only browser that fires change
		// events when programatically changing the value of file inputs.
		// This causes our validation to unnecessarily run and pop up required
		// alerts when resetting the form (e.g. after a successful submission).
		// To get around this, we need to remove the file inputs from the dom,
		// reset their values (thus removing any selected files), and then put
		// them back into the dom. Events will not fire on elements when they
		// are not in the dom.

		$fileInputs.each(function(i) {

			var $this = $fileInputs.eq(i);
			var $fieldContainer = dom.getFieldContainer($this);

			clearCustomFile($this);

			$this = $this.detach();

			clearNativeFile($this);

			$fieldContainer.append($this);

		});

	});

};

module.exports = {

	init: function() {

		addEventHandlers();

		// Grab the default text from the first file upload info field we find.
		defaultText = dom.inputContainer.find('.fb-file-upload-info').html();

	},

	isFileuploadType: function($col) {

		return $col.hasClass('fb-fileupload-type');

	},

	getFile: function($col) {

		return getFile($col.find('.fb-file-upload'));

	},

	getVal: function($col, fileUploadIdx) {

		var val = '';
		var fileObject;
		var size = null;
		var $fileInput = $col.find('.fb-file-upload');
		var haveFile = isFileSelected($fileInput);

		if (haveFile) {

			fileObject = getFile($fileInput);
			val = fileObject.name;
			size = fileObject.size;

		}

		return {
			name: dom.getFieldTitleText($col),
			value: val,
			size: size,
			// Save an index pointer to the list of actual uploaded files.
			fileUploadIdx: haveFile ? fileUploadIdx : null
		};

	},

	validateFileSelection: function($fileInput) {

		if (isFileSelected($fileInput)) {

			showFileName($fileInput);
			return true;

		}

		clearFile($fileInput);
		return false;

	},

	validateFileSize: function($fileInput) {

		var maxSize = parseInt($fileInput.attr('data-max-file-size'), 10);

		// Convert size to bytes.
		maxSize = maxSize * 1024 * 1024;

		if (isFileSelected($fileInput)) {

			if (getFile($fileInput).size > maxSize) {

				clearFile($fileInput);
				return false;

			}

		}

		showFileName($fileInput);
		return true;

	},

	validateFileType: function($fileInput) {

		var acceptedTypes = $fileInput.attr('data-accepted-types');

		acceptedTypes = acceptedTypes.replace(/\s/g, '').split(',');

		if (acceptedTypes.indexOf(getFile($fileInput).type) === -1) {

			clearFile($fileInput);
			return false;

		}

		showFileName($fileInput);
		return true;

	}

};
