'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var emitter = require('emitter');

var dom = require('form-widget-dom');
var settings = require('form-widget-settings');
var selectmenu = require('form-widget-selectmenu');

var populateStates = function($select, states) {

	$select.html(states);

	emitter.emit('fbWidget-address-states-populated', $select);

};

var getStates = function() {

	var $this = $(this);
	var $stateSelect = $this.closest('.fb-nested-col').find('select[id$="state"]');
	var country = $this.val();

	$.ajax({
		type: 'get',
		cache: true,
		url: settings.statesUrl + '&c=' + country,
		dataType: 'html'
	}).done(function(response) {

		populateStates($stateSelect, response);

	});

};

var syncPrefilled = function() {

	// Attempt to sync any prefilled country and state dropdowns.

	selectmenu.sync(dom.fbForm.find('.fb-address [id$="country"], .fb-address [id$="state"]'));

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-country-state-sync');

	emitter.on('fbWidget-inited', syncPrefilled);

	dom.fbForm.on('change.fb-country-state-sync', '.fb-address [id$="country"]', getStates);

};

var init = function() {

	addEventHandlers();

};

module.exports = {
	init: init
};
