'use strict';

var bodyStateDetection = require('state-detection')();

var directionMap = {
	'small': 0,
	'medium': 1,
	'large': 2,
	'xlarge': 3,
	'xxlarge': 4
};

bodyStateDetection.wasUpsized = function(oldState, newState) {

	return directionMap[newState] > directionMap[oldState];

};

bodyStateDetection.wasDownsized = function(oldState, newState) {

	return directionMap[newState] < directionMap[oldState];

};

module.exports = bodyStateDetection;
