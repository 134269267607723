'use strict';

var domCache = require('dom-cache');
var dom = {};

var setupDom = function() {

	dom.phoenix = $('#phoenix');
	dom.content = $('#content');

};

var init = function() {

	setupDom();

};

// Extend the generic dom-cache with app specific elements.
module.exports = Object.defineProperties(domCache, {

	phoenix: {

		get: function() {

			return dom.phoenix;

		}

	},

	content: {

		get: function() {

			return dom.content;

		}

	}

});

init();
