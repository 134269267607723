'use strict';

var isPortrait = function(width, height) {

	return width < height;

};

var isLandscape = function(width, height) {

	return width > height;

};

var isSquare = function(width, height) {

	return width === height;

};

var getOrientation = function(width, height) {

	return isPortrait(width, height) ? 'portrait' : isLandscape(width, height) ? 'landscape' : 'square';

};

var scaleByWidth = function(srcWidth, srcHeight, scaledWidth) {

	return scaledWidth * srcHeight / srcWidth || 0;

};

var scaleByHeight = function(srcWidth, srcHeight, scaledHeight) {

	return scaledHeight * srcWidth / srcHeight || 0;

};

var contain = function(srcWidth, srcHeight, targetWidth, targetHeight) {

	var scaledByWidth = {};
	var scaledByHeight = {};
	var retData;

	// Scale image to target width, maintaining aspect ratio.
	scaledByWidth.width = targetWidth;
	scaledByWidth.height = scaleByWidth(srcWidth, srcHeight, targetWidth);

	// Scale image to target height, maintaining aspect ratio.
	scaledByHeight.width = scaleByHeight(srcWidth, srcHeight, targetHeight);
	scaledByHeight.height = targetHeight;

	// Determine which scaled direction wins. We don't want any overflow.
	if (scaledByWidth.height <= targetHeight) {

		// Scaling by width didn't cause overflow.
		retData = scaledByWidth;
		retData.scaledBy = 'width';

	} else {

		// Scaling by width did cause overflow, scale by height instead.
		retData = scaledByHeight;
		retData.scaledBy = 'height';

	}

	return retData;

};

var cover = function(srcWidth, srcHeight, targetWidth, targetHeight) {

	var scaledByWidth = {};
	var scaledByHeight = {};
	var retData;

	// Scale image to target width, maintaining aspect ratio.
	scaledByWidth.width = targetWidth;
	scaledByWidth.height = scaleByWidth(srcWidth, srcHeight, targetWidth);

	// Scale image to target height, maintaining aspect ratio.
	scaledByHeight.width = scaleByHeight(srcWidth, srcHeight, targetHeight);
	scaledByHeight.height = targetHeight;

	// Determine which scaled direction wins. We want the entire target
	// to be covered/filled.
	if (scaledByWidth.height >= targetHeight) {

		// Scaling by width filled the target.
		retData = scaledByWidth;
		retData.scaledBy = 'width';

	} else {

		// Scaling by width did not fill the target, scale by height instead.
		retData = scaledByHeight;
		retData.scaledBy = 'height';

	}

	// Determine any cropping/overflow.
	retData.xOverflow = retData.width - targetWidth;
	retData.yOverflow = retData.height - targetHeight;

	// Determine offset amounts to center within target.
	retData.xOffset = -retData.xOverflow / 2;
	retData.yOffset = -retData.yOverflow / 2;

	return retData;

};

module.exports = {
	isPortrait: isPortrait,
	isLandscape: isLandscape,
	isSquare: isSquare,
	getOrientation: getOrientation,
	scaleByWidth: scaleByWidth,
	scaleByHeight: scaleByHeight,
	contain: contain,
	cover: cover
};
