'use strict';

var dom = require('form-widget-dom');
var emitter = require('emitter');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var $ratingFields;
var ratingExpandedClass = 'fb-rating-expanded';
var ratingCompactClass = 'fb-rating-compact';
var breakPoints = {
	// Once the form is at least this wide, expand rating fields.
	expandRating: 700
};

var syncRadioToSelect = function() {

	var $this = $(this);
	var idx = +$this.attr('data-dropdown-idx');
	// The group name of the radio button matches the ID of the select box.
	var name = $this.attr('name');
	var $select = $('#'+name);

	// Change the selected option of the native select box to match
	// the radio button choice.
	$select.prop('selectedIndex', idx);

	emitter.emit('fbWidget-rating-radio-clicked', $select);

};

var syncSelectToRadio = function() {

	var $select = $(this);
	// The ID of the select box matches the ID of the radio button, without
	// the answer index.
	var selectId = $select.attr('id');
	// Offset for the dummy 'Select' option.
	var selectedIndex = this.selectedIndex - 1;

	if (selectedIndex >= 0) {

		// Change the radio button choice to match the select box.
		$('#' + selectId + '-' + selectedIndex).prop('checked', true);

	} else {

		// The dummy 'Select' option was chosen. Uncheck all radio buttons
		// for this question.

		$('[name="' + selectId + '"]').prop('checked', false);

	}

};

var setupDom = function() {

	// Setup cache.
	$ratingFields = dom.inputContainer.find('.fb-rating-type');

};

var adjustRatingLayout = function(data) {

	var newLayout = false;
	var formWidth = data.formWidth;

	if (formWidth >= breakPoints.expandRating) {

		if ($ratingFields.hasClass(ratingCompactClass)) {

			newLayout = true;

		}

		$ratingFields.removeClass(ratingCompactClass);
		$ratingFields.addClass(ratingExpandedClass);

		if (newLayout) {

			emitter.emit('fbWidget-rating-layout-adjusted', {layout: 'expanded'});

		}

	} else {

		if ($ratingFields.hasClass(ratingExpandedClass)) {

			newLayout = true;

		}

		$ratingFields.removeClass(ratingExpandedClass);
		$ratingFields.addClass(ratingCompactClass);

		if (newLayout) {

			emitter.emit('fbWidget-rating-layout-adjusted', {layout: 'compact'});

		}

	}

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-rating');

	// Sync rating radio button selection to select box selection.
	dom.fbForm.on('click.fb-rating', '.fb-rating-type input[type="radio"]', syncRadioToSelect);

	// Sync rating select box selection to radio button selection.
	dom.fbForm.on('change.fb-rating', '.fb-rating-type select', syncSelectToRadio);

	emitter.on('fbWidget-window-resize', adjustRatingLayout);

	emitter.on('fbWidget-destroy', function() {

		$ratingFields = null;

	});

};

module.exports = {

	init: function() {

		setupDom();
		addEventHandlers();

	},

	isElementRatingRadio: function($element) {

		return $element.is('input[name^="rating"]');

	},

	isElementRatingField: function($element) {

		return !!$element.closest('.fb-rating-type').length;

	},

	isRatingType: function($col) {

		return $col.hasClass('fb-rating-type');

	},

	getVal: function($col) {

		// Just parse the compact UI since it's easier to work with and will
		// be in sync with the expanded UI.
		var $els = dom.getFieldInputs($col.find('.fb-rating-compact-wrapper'));
		var name = dom.getFieldTitleText($col);
		var vals = [];

		$els.each(function(i) {

			var $el = $els.eq(i);

			vals.push({
				name: dom.getNestedFieldTitleText($el),
				value: $el.val()
			});

		});

		return {
			name: name,
			value: vals
		};

	}

};
