'use strict';

var dom = require('form-widget-dom');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

// It is possible for the user to add an embedded field via the builder,
// but never add the code. Remove any empty embed fields from the form.
var removeEmptyEmbeds = function() {

	var $embedFields = dom.inputContainer.find('.fb-embed-type');

	$embedFields.each(function(i) {

		var $this = $embedFields.eq(i);

		if (!$this.children().length) {

			$this.closest('.fb-row').remove();

		}

	});

};

var parseCode = function() {

	var $videoWrappers = dom.inputContainer.find('.fb-embed-wrapper');

	$videoWrappers.each(function(i/*, el*/) {

		// Fix for z-index issues.

		var $videoWrapper = $videoWrappers.eq(i),
			// jQuery .data does not work on object/embed elements
			$embedCode = $($videoWrapper.attr("data-embed-code")),
			iframeUrl,
			queryParam;

		if ($embedCode.is("iframe")) {

			// Handle iFrame.

			// Strip Vimeo text links.

			/*if ($embedCode.length > 1) {

				// More markup than just iFrame was entered into the cpanel.

				$embedCode = $($embedCode[0]);

			}*/

			iframeUrl = $embedCode.attr("src");

			queryParam = (iframeUrl.indexOf("?") === -1 ? "?" : "&") + "wmode=transparent";

			$embedCode.attr("src", iframeUrl + queryParam);

		} else {

			// Handle old school object>embed markup.

			$embedCode
				.prepend('<param name="wmode" value="transparent">')
				.find("embed")
					.attr("wmode", "transparent");

		}

		$videoWrapper.html($embedCode);

	});

};

module.exports = {

	init: function() {

		removeEmptyEmbeds();
		parseCode();

	}

};