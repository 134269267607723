'use strict';

var dom = require('form-widget-dom');
var settings = require('form-widget-settings');

module.exports = {

	// Remove unneeded elements from DOM depending on desired UI based on
	// feature tests.
	init: function() {

		if (settings.useNativeTime) {

			// Get rid of time colon and native selects left over from the
			// 3 dropdown UI.

			dom.fbForm
				.find('.fb-time-type select, .fb-time-colon')
					.remove();

		} else {

			// Get rid of the native time element.

			dom.fbForm.find('.fb-native-time').remove();

		}

	},

	isTimeType: function($col) {

		return $col.hasClass('fb-time-type');

	},

	isCustom: function($el) {

		return !!$el.closest('.js-fb-custom-time').length;

	},

	hasGroupValidation: function($el) {

		return !!$el.attr('data-parsley-timegroup');
	},

	getVal: function($col) {

		var $els = dom.getFieldInputs($col);
		var name = dom.getFieldTitleText($col);
		var val = '';

		if (settings.useNativeTime) {

			// Get the value of the native time element.
			return {
				name: name,
				value: $els.val()
			};

		}

		// Construct a value based on the custom select boxes.
		$els.each(function(i) {

			val += $els.eq(i).val();

			if (i === 0) {

				val += ':';

			} else if (i === 1) {

				val += ' ';

			}

		});

		val = val === ': ' ? '' : val;

		return {
			name: name,
			value: val
		};

	},

	validateCustom: function($element) {

		var $selects = $element.closest('.fb-time-type').find('select');
		var validCnt = 0;

		$selects.each(function(i) {

			if ($selects.eq(i).val()) {

				validCnt++;

			}

		});

		return validCnt === $selects.length;

	}

};