'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var Galleria = (typeof window !== "undefined" ? window.Galleria : typeof global !== "undefined" ? global.Galleria : null);

var domCache = require('dom-cache');

var add = function() {

	Galleria.addTheme({
		name: 'fullscreen-slideshow',
		author: 'pbiz',
		// css: 'galleria.classic.css',
		// This must be false for the build to work.
		// http://developista.wordpress.com/2011/12/29/galleria-js-and-css-compression-with-minification/
		css: false,
		defaults: {
			trueFullscreen: false,
			maxScaleRatio: 1,
			thumbnails: false,
			showCounter: false,
			showInfo: false,

			// Setup custom options.
			_musicIsPaused: true,
			_hasMusic: false,
			_showControls: true
		},
		init: function(options) {

			console.log('galleria init from theme', options);

			// Galleria.requires(1.4, 'This version of Classic theme requires Galleria 1.4 or later');

			var galleriaInstance = this;
			var dom = {};

			var getCtrlBtns = function() {

				var markup = '<div class="fullscreen-slideshow__btns">';

				if (options._showControls) {

					markup +=
						'<div class="fullscreen-slideshow__btn fullscreen-slideshow__btn--prev">' +
							'<i class="fullscreen-slideshow__btn-icon pbi-chevron-left"></i>' +
						'</div>' +
						'<div class="fullscreen-slideshow__btn fullscreen-slideshow__btn--play">' +
							'<i class="fullscreen-slideshow__btn-icon fullscreen-slideshow__btn-icon--play pbi-play"></i>' +
							'<i class="fullscreen-slideshow__btn-icon fullscreen-slideshow__btn-icon--pause pbi-pause"></i>' +
						'</div>' +
						'<div class="fullscreen-slideshow__btn fullscreen-slideshow__btn--next">' +
							'<i class="fullscreen-slideshow__btn-icon pbi-chevron-right"></i>' +
						'</div>';

				}

				markup += '</div>';

				return markup;

			};

			var getActionBtns = function() {

				var markup =
					'<div class="fullscreen-slideshow__btns">' +
						(options._hasMusic ? '<div class="fullscreen-slideshow__btn fullscreen-slideshow__btn--music' + (options._musicIsPaused ? ' fullscreen-slideshow__btn--idle' : '') + '"><i class="fullscreen-slideshow__btn-icon pbi-music"></i></div>' : '') +
						'<div class="fullscreen-slideshow__btn fullscreen-slideshow__btn--close">' +
							'<i class="fullscreen-slideshow__btn-icon pbi-cross"></i>' +
						'</div>' +
					'</div>';

				return markup;

			};

			var getInfo = function() {

				var markup =
					'<div class="fullscreen-slideshow__info-text-wrapper">' +
						'<div class="fullscreen-slideshow__info-text"></div>' +
					'</div>';

				return markup;

			};

			var getInfoBar = function() {

				var markup = '<div class="fullscreen-slideshow__info-bar">' +
					getCtrlBtns() +
					getInfo() +
					getActionBtns() +
					'</div>';

				return markup;

			};

			var toggleSlideshowStateClass = function() {

				dom.playBtn.toggleClass('fullscreen-slideshow__btn--playing', galleriaInstance._playing);

			};

			var setupDom = function() {

				dom.mainContainer = $('.galleria-container');
				dom.infoBar = $(getInfoBar());
				dom.playBtn = dom.infoBar.find('.fullscreen-slideshow__btn--play');
				dom.infoText = dom.infoBar.find('.fullscreen-slideshow__info-text');
				dom.galleriaPrev = galleriaInstance.$('image-nav-left');
				dom.galleriaNext = galleriaInstance.$('image-nav-right');

			};

			var addEventHandlers = function() {

				// Disable right clicks.
				dom.mainContainer.on('contextmenu', '.galleria-layer', function() {

					return false;

				});

				// Clicking caption that is a link.
				dom.infoBar.on('click', '.fullscreen-slideshow__info-text--link', function() {

					var data = galleriaInstance.getData();

					// galleriaInstance.exitFullscreen();

					domCache.document.triggerHandler({
						type: 'fullscreen_slideshow_caption_click',
						imgData: data
					});

				});

				// Info bar previous button.
				dom.infoBar.on('click', '.fullscreen-slideshow__btn--prev', function() {

					dom.galleriaPrev.click();

				});

				// Info bar play/pause button.
				dom.infoBar.on('click', '.fullscreen-slideshow__btn--play', function() {

					galleriaInstance.playToggle();

				});

				// Info bar next button.
				dom.infoBar.on('click', '.fullscreen-slideshow__btn--next', function() {

					dom.galleriaNext.click();

				});

				// Info bar music button.
				dom.infoBar.on('click', '.fullscreen-slideshow__btn--music', function() {

					domCache.document.triggerHandler({
						type: 'fullscreen_slideshow_music_toggle'
					});

					$(this).toggleClass('fullscreen-slideshow__btn--idle');

				});

				// Info bar close button.
				dom.infoBar.on('click', '.fullscreen-slideshow__btn--close', function() {

					galleriaInstance.exitFullscreen();

				});

				galleriaInstance.bind('play', toggleSlideshowStateClass);

				galleriaInstance.bind('pause', toggleSlideshowStateClass);

				// Galleria idle event.
				galleriaInstance.bind('idle_enter', function() {

					dom.infoBar.addClass('fullscreen-slideshow__info-bar--hidden');

				});

				// Galleria idle event.
				galleriaInstance.bind('idle_exit', function() {

					dom.infoBar.removeClass('fullscreen-slideshow__info-bar--hidden');

				});

				// Galleria fullscreen event.
				galleriaInstance.bind('fullscreen_enter', function() {

					domCache.document.triggerHandler({
						type: 'fullscreen_slideshow_enter'
					});

				});

				// Galleria fullscreen event.
				galleriaInstance.bind('fullscreen_exit', function() {

					domCache.document.triggerHandler({
						type: 'fullscreen_slideshow_exit'
					});

					galleriaInstance.destroy();

				});

				// Galleria image load event.
				galleriaInstance.bind('loadstart', function() {

					// Reset caption info.

					var itemData = galleriaInstance.getData();
					var isLink = itemData.isCaptionLink;
					var markup = '';

					markup += itemData.title;
					markup += itemData.description ? (itemData.title ? ': ' + itemData.description : itemData.description) : '';
					// Space ensures a consistent line height in case no caption text.
					markup += '&nbsp;<i class="fullscreen-slideshow__info-text-icon pbi-external-link"></i>';

					dom.infoText
						.css('opacity', 0)
						.toggleClass('fullscreen-slideshow__info-text--link', isLink)
						.html(markup)
						.animate({opacity: 1}, options.transitionSpeed);

					if (itemData.color) {

						// Color shift the background.

						dom.mainContainer.css('background-color', '#' + itemData.color);

					}

				});

				galleriaInstance.bind('loadstart', function(e) {

					if (!e.cached) {

						domCache.document.triggerHandler({
							type: 'fullscreen_slideshow_image_load_start'
						});
					}

				});

				galleriaInstance.bind('loadfinish', function() {

					domCache.document.triggerHandler({
						type: 'fullscreen_slideshow_image_load_finish'
					});

				});

				if (!Galleria.TOUCH) {
					galleriaInstance.addIdleState( galleriaInstance.get('image-nav-left'), { left: -50 });
					galleriaInstance.addIdleState( galleriaInstance.get('image-nav-right'), { right: -50 });
				}

			};

			var injectInfoBar = function() {

				galleriaInstance.$('stage').append(dom.infoBar);

			};

			setupDom();
			addEventHandlers();
			toggleSlideshowStateClass();
			injectInfoBar();

		}
	});

};

module.exports = {

	add: add

};
